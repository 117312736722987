/**
 * Returns a Nibble (0-15) which equals the given bits.
 *
 * @example
 * byte.write([1,0,1,0]) => 10
 *
 * @param {Array} nibble 4-bit unsigned integer
 * @return {Number}
 */
export default (function (nibble) {
    if (!Array.isArray(nibble) || nibble.length !== 4)
        throw new RangeError('invalid array length');
    var result = 0;
    for (var i = 0; i < 4; i++)
        if (nibble[3 - i])
            result |= 1 << i;
    return result;
});
