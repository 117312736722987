import and from './and';
import circularShiftLeft from './circular-shift-left';
import circularShiftRight from './circular-shift-right';
import nand from './nand';
import nor from './nor';
import not from './not';
import or from './or';
import reduceAnd from './reduce-and';
import reduceNand from './reduce-nand';
import reduceNor from './reduce-nor';
import reduceOr from './reduce-or';
import reduceXnor from './reduce-xnor';
import reduceXor from './reduce-xor';
import toBoolean from './to-boolean';
import toString from './to-string';
import xnor from './xnor';
import xor from './xor';
export { and, circularShiftLeft, circularShiftRight, nand, nor, not, or, reduceAnd, reduceNand, reduceNor, reduceOr, reduceXnor, reduceXor, toBoolean, toString, xnor, xor, };
export default {
    and: and,
    circularShiftLeft: circularShiftLeft,
    circularShiftRight: circularShiftRight,
    nand: nand,
    nor: nor,
    not: not,
    or: or,
    reduceAnd: reduceAnd,
    reduceNand: reduceNand,
    reduceNor: reduceNor,
    reduceOr: reduceOr,
    reduceXnor: reduceXnor,
    reduceXor: reduceXor,
    toBoolean: toBoolean,
    toString: toString,
    xnor: xnor,
    xor: xor,
};
