import * as t from 'io-ts';

import { EthAddress, fromEnum } from '../runtime';
import { ImmutableMethodParams, ImmutableMethodResults } from './types';

const { ImmutablePaginatedResultCodec } = ImmutableMethodResults;
const { ImmutablePaginatedParamsCodec } = ImmutableMethodParams;

/**
 * Exchange types onramp, offramp
 */
export enum ExchangeType {
  ONRAMP = 'onramp',
  OFFRAMP = 'offramp',
}

export const ExchangeTypeT = fromEnum<ExchangeType>(
  'ExchangeType',
  ExchangeType,
);

export const WidgetParamsCodec = t.intersection([
  t.type({
    supported_currencies: t.array(t.string),
  }),
  t.partial({
    amount: t.string,
    theme: t.string,
  }),
]);

export const ImmutableCreateExchangeV3ParamsCodec = t.intersection([
  t.type({
    wallet_address: EthAddress,
    provider: t.string,
    type: ExchangeTypeT,
  }),
  t.partial({
    widget: WidgetParamsCodec,
  }),
]);

export type ImmutableCreateExchangeV3Params = t.OutputOf<
  typeof ImmutableCreateExchangeV3ParamsCodec
>;

export const ImmutableCreateExchangeV3ResponseCodec = t.type({
  id: t.number,
  wallet_address: EthAddress,
  provider: t.string,
  type: t.string,
  url: t.string,
});

export type ImmutableCreateExchangeV3Response = t.OutputOf<
  typeof ImmutableCreateExchangeV3ResponseCodec
>;

// Primary flow
export enum NFTTransactionStatus {
  Created = 'created',
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
  WaitingAuthorization = 'waitingAuthorization',
}

export const ImmutableTransactionBaseCodec = t.type({
  contract_address: EthAddress,
  offer_id: t.string,
  user_wallet_address: EthAddress,
  seller_wallet_address: EthAddress,
  provider: t.string,
});

export const ImmutableNFTCheckoutPrimaryParamsCodec =
  ImmutableTransactionBaseCodec;

export type ImmutableNFTCheckoutPrimaryParams = t.OutputOf<
  typeof ImmutableNFTCheckoutPrimaryParamsCodec
>;

export const ImmutableNFTCheckoutPrimaryResponseCodec = t.intersection([
  ImmutableTransactionBaseCodec,
  t.type({
    transaction_id: t.string,
    token_id: t.string,
    url: t.string,
  }),
]);

export type ImmutableNFTCheckoutPrimaryResponse = t.TypeOf<
  typeof ImmutableNFTCheckoutPrimaryResponseCodec
>;

// Secondary flow
export const ImmutableNFTSecondaryTransactionBaseCodec = t.type({
  user_wallet_address: EthAddress,
  order_id: t.union([t.number, t.string]),
  provider: t.string,
});

export const ImmutableNFTCheckoutSecondaryParamsParamsCodec = t.intersection([
  ImmutableNFTSecondaryTransactionBaseCodec,
  t.partial({
    widget: t.partial({
      theme: t.string,
    }),
  }),
]);

export type ImmutableNFTCheckoutSecondaryParams = t.OutputOf<
  typeof ImmutableNFTCheckoutSecondaryParamsParamsCodec
>;

export const ImmutableNFTCheckoutSecondaryResponseCodec = t.intersection([
  ImmutableNFTSecondaryTransactionBaseCodec,
  t.type({
    transaction_id: t.string,
    token_id: t.string,
    contract_address: EthAddress,
    url: t.string,
  }),
]);

export type ImmutableNFTCheckoutSecondaryResponse = t.OutputOf<
  typeof ImmutableNFTCheckoutSecondaryResponseCodec
>;

export const ImmutableGetNFTPrimaryTransactionStatusResponseCodec =
  t.intersection([
    ImmutableTransactionBaseCodec,
    t.type({
      transaction_id: t.string,
      status: t.string,
    }),
    t.partial({
      from_amount: t.number,
      from_currency: t.string,
      fees_amount: t.number,
      created_at: t.string,
    }),
  ]);

export type ImmutableGetNFTPrimaryTransactionStatusResponse = t.TypeOf<
  typeof ImmutableGetNFTPrimaryTransactionStatusResponseCodec
>;

export const ImmutableGetNFTSecondaryTransactionStatusResponseCodec =
  t.intersection([
    ImmutableNFTSecondaryTransactionBaseCodec,
    t.type({
      transaction_id: t.string,
      status: t.string,
      token_address: EthAddress,
      token_id: t.string,
    }),
  ]);

export type ImmutableGetNFTSecondaryTransactionStatusResponse = t.TypeOf<
  typeof ImmutableGetNFTSecondaryTransactionStatusResponseCodec
>;

export const ImmutableGetPrimaryTransactionsHistoryParamsCodec = t.intersection(
  [
    ImmutablePaginatedParamsCodec,
    t.partial({
      id: t.string,
      status: t.string,
      wallet_address: EthAddress,
      seller_wallet_address: EthAddress,
      provider: t.string,
      mint_id: t.string,
    }),
  ],
);

export const ImmutableGetNFTPrimaryTransactionsHistoryResultCodec =
  t.intersection([
    ImmutablePaginatedResultCodec,
    t.type({
      result: t.array(ImmutableGetNFTPrimaryTransactionStatusResponseCodec),
    }),
  ]);

export type ImmutableGetPrimaryTransactionsHistoryParams = t.TypeOf<
  typeof ImmutableGetPrimaryTransactionsHistoryParamsCodec
>;

export type ImmutableGetPrimaryTransactionsHistoryParamsTS = t.OutputOf<
  typeof ImmutableGetPrimaryTransactionsHistoryParamsCodec
>;

export type ImmutableGetNFTPrimaryTransactionsHistoryResult = t.OutputOf<
  typeof ImmutableGetNFTPrimaryTransactionsHistoryResultCodec
>;
