export const LocalStorage = {
  get: function (key) {
    const parsedValue = localStorage.getItem(key);
    return JSON.parse(parsedValue);
  },

  set: function (key, value) {
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(key, stringifiedValue);
  },

  remove: function (key) {
    localStorage.removeItem(key);
  },
};
