import writeByte from '../byte/write';
import readBuffer from './read';
/**
 * Modifies the buffer's bits to equal newBits starting at bitOffset.
 *
 * @example
 * modifyBuffer(buffer, [0,0,1,0], 0) => buffer was modified
 *
 * @param {Buffer} buffer the buffer to modify
 * @param {Array} bits the bits to insert
 * @param {Number} offset where to start (in bits)
 * @returns {undefined}
 */
export default (function (buffer, bits, offset) {
    if (offset === void 0) { offset = 0; }
    var start = Math.floor(offset / 8);
    var end = Math.ceil((offset + bits.length) / 8);
    var subBuffer = buffer.slice(start, end);
    var byteData = readBuffer(subBuffer);
    var subOffset = offset % 8;
    for (var i = 0; i < bits.length; i++)
        byteData[subOffset++] = bits[i];
    var length = end - start;
    for (var i_1 = 0; i_1 < length; i_1++)
        subBuffer[i_1] = writeByte(byteData.slice(i_1 * 8, (i_1 + 1) * 8));
});
