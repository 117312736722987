// @TODO: refactor the code so that these arent necessary.
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */

import autoBind from 'auto-bind';
import axios, { AxiosRequestConfig } from 'axios';
import { BigNumber, Signer } from 'ethers';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as https from 'https';
import { Branded, Errors, IntBrand } from 'io-ts';
import queryString from 'query-string';

import { getAssetID, getMintingBlobHash } from '../crypto';
import {
  decodeForFunction,
  errorsToError,
  formatError,
  taskEitherWithError,
  tokenQuantizedAmount,
  valueOrThrow,
} from '../libs';
import {
  AddMetadataSchemaToCollectionParams,
  AddMetadataSchemaToCollectionResult,
  AddMetadataSchemaToCollectionResultCodec,
  BurnAddress,
  CreateCollectionParams,
  CreateCollectionsResult,
  CreateCollectionsResultsCodec,
  CreateProjectParams,
  CreateProjectResult,
  CreateProjectResultsCodec,
  ERC20Token,
  ERC721Token,
  ERC721TokenType,
  EthAddress,
  ETHToken,
  ETHTokenType,
  ExchangeProvider,
  FeeType,
  GetAwaitingOrderStatusResponse,
  GetAwaitingOrderStatusResponseCodec,
  GetMetadataSchemaParams,
  GetMetadataSchemaResult,
  GetMetadataSchemaResultCodec,
  GetProjectParams,
  HexadecimalString,
  ImmutableCreateExchangeV3Params,
  ImmutableCreateExchangeV3Response,
  ImmutableCreateExchangeV3ResponseCodec,
  ImmutableExchangeType,
  ImmutableGetNFTPrimaryTransactionsHistoryResult,
  ImmutableGetNFTPrimaryTransactionsHistoryResultCodec,
  ImmutableGetNFTPrimaryTransactionStatusResponse,
  ImmutableGetNFTPrimaryTransactionStatusResponseCodec,
  ImmutableGetNFTSecondaryTransactionStatusResponse,
  ImmutableGetNFTSecondaryTransactionStatusResponseCodec,
  ImmutableGetPrimaryTransactionsHistoryParams,
  ImmutableGetPrimaryTransactionsHistoryParamsCodec,
  ImmutableGetPrimaryTransactionsHistoryParamsTS,
  ImmutableMethodParams,
  ImmutableMethodResults,
  ImmutableNFTCheckoutPrimaryParams,
  ImmutableNFTCheckoutPrimaryResponse,
  ImmutableNFTCheckoutPrimaryResponseCodec,
  ImmutableNFTCheckoutSecondaryParams,
  ImmutableNFTCheckoutSecondaryResponse,
  ImmutableNFTCheckoutSecondaryResponseCodec,
  MintableERC20Token,
  MintableERC721Token,
  MintableERC721TokenType,
  MintBody,
  MintV2Body,
  OrderParams,
  PositiveBigNumber,
  ProjectResult,
  ProjectResultCodec,
  ProjectsResult,
  ProjectsResultCodec,
  RegistrationMethodParams,
  RiskAssessmentHeader,
  StarkMethodParams,
  Token,
  UpdateCollectionParams,
  UpdateCollectionsResultCodec,
  UpdateCollectionsResults,
  UpdateMetadataSchemaByNameParams,
  UpdateMetadataSchemaByNameResult,
  UpdateMetadataSchemaByNameResultCodec,
  VerifyOTPParams,
  VerifyOTPResponse,
  VerifyOTPResponseCodec,
} from '../types';
import { experimental } from '../utils/experimental';
import { addEthHeaders, signMessage } from '../utils/payload-signer';
import { ImmutableXWallet } from './ImmutableXWallet';
import { RequestClient } from './RequestClient';
import ImmutableCreateExchangeResultCodec = ImmutableMethodResults.ImmutableCreateExchangeResultCodec;
import ImmutableGetExchangeResultCodec = ImmutableMethodResults.ImmutableGetExchangeResultCodec;
import ImmutableGetMoonpaySignatureResultCodec = ImmutableMethodResults.ImmutableGetMoonpaySignatureResultCodec;
import ImmutableGetMoonpaySignatureRequestCodec = ImmutableMethodResults.ImmutableGetMoonpaySignatureRequestCodec;
import ImmutableGetMoonpaySellSignatureRequestCodec = ImmutableMethodResults.ImmutableGetMoonpaySellSignatureRequestCodec;
import ImmutableGetMoonpayPrimarySellSignatureRequestCodec = ImmutableMethodResults.ImmutableGetMoonpayPrimarySellSignatureRequestCodec;
import { version } from '../version';

export type ImmutableXClientParams = {
  publicApiUrl: string;
  apiKey?: string;
  signer?: Signer;
  gasLimit?: string;
  gasPrice?: string;
  starkContractAddress?: string;
  registrationContractAddress?: string;
  enableDebug?: boolean;
};

export const V2 = 'v2';
export const V3 = 'v3';

/**
 * Immutable X REST API Client
 */
export class ImmutableXClient {
  private agent: https.Agent;

  private requests: RequestClient;

  constructor(
    private publicApiUrl: string,
    private wallet: ImmutableXWallet,
    private contractAddress: EthAddress,
    private registrationContractAddress: EthAddress,
    private _address: EthAddress,
    private _starkPublicKey: HexadecimalString,
    private enableDebug: boolean = false,
    private readonly apiKey?: string,
  ) {
    this.agent = new https.Agent();
    this.requests = new RequestClient(publicApiUrl, enableDebug, apiKey);
    autoBind(this);
  }

  public static buildF(
    params: ImmutableXClientParams,
  ): TE.TaskEither<Error, ImmutableXClient> {
    return taskEitherWithError(() => ImmutableXClient.build(params));
  }

  private async signEthMessageAsync(message: string): Promise<{
    message: string;
    ethAddress: string;
    ethSignature: string;
  }> {
    return valueOrThrow(
      await pipe(this.signEthMessage(message), TE.mapLeft(formatError))(),
    );
  }

  private signEthMessage(message: string) {
    return signMessage(
      message,
      this.wallet.controller.getAddress,
      this.wallet.controller.signRaw,
    );
  }

  public async getERC20Balance(
    owner: string,
    contractAddress: string,
  ): Promise<string> {
    return this.wallet.getBalance(owner, contractAddress);
  }

  public async getERC20Allowance(
    contractAddress: string,
    owner: string,
    spender?: string,
  ): Promise<string> {
    const allowance = await this.wallet.getAllowance(
      contractAddress,
      owner,
      spender ?? this.contractAddress,
    );
    return allowance.toString();
  }

  public async hasERC20Allowance(
    contractAddress: string,
    amount: string | BigNumber,
    owner: string,
    spender?: string,
  ): Promise<boolean> {
    const allowance = await this.wallet.getAllowance(
      contractAddress,
      owner,
      spender ?? this.contractAddress,
    );
    return allowance.gte(BigNumber.from(amount));
  }

  public static async build({
    publicApiUrl,
    signer,
    starkContractAddress,
    registrationContractAddress,
    gasLimit,
    gasPrice,
    enableDebug = false,
    apiKey,
  }: ImmutableXClientParams) {
    if (signer && starkContractAddress && registrationContractAddress) {
      const parsedGasLimit = gasLimit ? BigNumber.from(gasLimit) : undefined;
      const parsedGasPrice = gasPrice ? BigNumber.from(gasPrice) : undefined;
      const wallet = await ImmutableXWallet.build({
        publicApiUrl,
        signer,
        gasLimit: parsedGasLimit,
        gasPrice: parsedGasPrice,
      });

      const contractAddressT = valueOrThrow(
        EthAddress.decode(starkContractAddress),
      );
      const registrationContractAddressT = valueOrThrow(
        EthAddress.decode(registrationContractAddress),
      );
      const starkPublicKey = valueOrThrow(
        HexadecimalString.decode(wallet.controller.starkPublicKey),
      );
      const address = await wallet.controller.getAddress();
      return new ImmutableXClient(
        publicApiUrl,
        wallet,
        contractAddressT,
        registrationContractAddressT,
        address,
        starkPublicKey,
        enableDebug,
        apiKey,
      );
    }
    return new ImmutableXClient(
      publicApiUrl,
      {} as any,
      {} as any,
      {} as any,
      {} as any,
      {} as any,
      enableDebug,
      apiKey,
    );
  }

  public get address() {
    return this._address;
  }

  public get starkPublicKey() {
    return this._starkPublicKey;
  }

  public buildOptions(): AxiosRequestConfig {
    const options: AxiosRequestConfig = {
      baseURL: this.publicApiUrl,
      httpsAgent: this.agent,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // If provided, add our api key to all request headers.
    if (this.apiKey) {
      options.headers['x-api-key'] = this.apiKey;
    }
    return options;
  }

  private replaceApiVersion(path: string, version: string): string {
    return path.replace(/\/v[0-9]+$/, `/${version}`);
  }

  private get<T>(
    path: string,
    decode: (x: any) => E.Either<Errors, T>,
    version?: string,
  ): TE.TaskEither<Error, T> {
    const options = this.buildOptions();

    if (version && options.baseURL) {
      options.baseURL = this.replaceApiVersion(options.baseURL, version);
    }

    return pipe(
      taskEitherWithError(() => axios.get(`${path}`, options)),
      TE.chain(r =>
        pipe(decode(r.data), E.mapLeft(errorsToError), TE.fromEither),
      ),
    );
  }

  private post<T>(
    path: string,
    data: any,
    decode: (x: any) => E.Either<Errors, T>,
    version?: string,
    headers?: Record<string, string>,
  ): TE.TaskEither<Error, T> {
    const options = this.buildOptions();

    if (version && options.baseURL) {
      options.baseURL = this.replaceApiVersion(options.baseURL, version);
    }

    // Add headers if any
    if (headers) {
      options.headers = { ...options.headers, ...headers };
    }

    return pipe(
      taskEitherWithError(() => axios.post(`${path}`, data, options)),
      TE.chain(r =>
        pipe(decode(r.data), E.mapLeft(errorsToError), TE.fromEither),
      ),
    );
  }

  private delete<T>(
    path: string,
    data: any,
    decode: (x: any) => E.Either<Errors, T>,
    headers?: Record<string, string>,
    version?: string,
  ): TE.TaskEither<Error, T> {
    return pipe(
      taskEitherWithError(() => {
        const options = this.buildOptions();

        if (version && options.baseURL) {
          options.baseURL = this.replaceApiVersion(options.baseURL, version);
        }

        if (headers) {
          options.headers = { ...options.headers, ...headers };
        }

        return axios.delete(`${path}`, {
          ...options,
          data,
        });
      }),
      TE.chain(r =>
        pipe(decode(r.data), E.mapLeft(errorsToError), TE.fromEither),
      ),
    );
  }

  public registerImxF(
    params: ImmutableMethodParams.ImmutableRegisterParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableRegisterResult> {
    const { etherKey, starkPublicKey, email } = params;
    let verifyEth = false;
    const currentTime = Math.round(new Date().getTime() / 1000);

    if (currentTime > 1633546800) {
      // 1633546800 = Thursday, 7 October 2021 06:00:00 GMT+11:00
      verifyEth = true;
    }

    if (!verifyEth) {
      return pipe(
        {
          body: {
            email,
            ether_key: etherKey.toLowerCase(),
            stark_key: starkPublicKey,
            nonce: 0,
          },
        },
        TE.of,
        TE.bind('signature', ({ body }) =>
          this.wallet.controller.signUserRegistration({
            etherKey: body.ether_key,
            starkPublicKey: body.stark_key,
            nonce: String(body.nonce),
          }),
        ),
        TE.chain(({ signature, body }) => {
          const data = JSON.stringify({
            ...body,
            stark_signature: signature,
          });

          return this.post(
            'users',
            data,
            ImmutableMethodResults.ImmutableRegisterResultCodec.decode,
          );
        }),
      );
    }
    return pipe(
      {
        body: {
          email,
          ether_key: etherKey.toLowerCase(),
          stark_key: starkPublicKey,
          stark_signature: '',
          eth_signature: '',
        },
      },
      TE.of,
      TE.bind('starkSignature', ({ body }) =>
        this.wallet.controller.signUserRegistrationVerifyEth({
          etherKey: body.ether_key,
          starkPublicKey: body.stark_key,
        }),
      ),
      TE.bind('ethSignature', () =>
        taskEitherWithError(() =>
          this.wallet.controller.signRaw(
            'Only sign this key linking request from Immutable X',
          ),
        ),
      ),
      TE.chain(({ starkSignature, ethSignature, body }) => {
        const data = JSON.stringify({
          ...body,
          stark_signature: starkSignature,
          eth_signature: ethSignature,
        });
        return this.post(
          'users',
          data,
          ImmutableMethodResults.ImmutableRegisterResultCodec.decode,
        );
      }),
    );
  }

  public async fullWithdrawal(
    contractAddress: string,
    starkPublicKey: string,
    vaultId: string,
  ) {
    const result = await this.wallet.controller.fullWithdrawal(
      contractAddress,
      starkPublicKey,
      vaultId,
    );

    return this.wallet.sendTransaction(result);
  }

  public getUserF(
    params: ImmutableMethodParams.ImmutableGetUserParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetUserResult> {
    return this.get(
      `users/${params.user.toLowerCase()}`,
      ImmutableMethodResults.ImmutableGetUserResultCodec.decode,
    );
  }

  public async getUser(params: ImmutableMethodParams.ImmutableGetUserParamsTS) {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetUserParamsCodec.decode,
      this.getUserF,
    );
  }

  public async registerImx(
    params: ImmutableMethodParams.ImmutableRegisterParamsTS,
  ) {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableRegisterParamsCodec.decode,
      this.registerImxF,
    );
  }

  public isRegisteredF(
    params: ImmutableMethodParams.ImmutableGetUserParams,
  ): TE.TaskEither<Error, boolean> {
    return pipe(
      this.getUserF(params),
      TE.fold(
        () => T.of(false),
        () => T.of(true),
      ),
      T.chain(result => TE.of(result)),
    );
  }

  public async isRegistered(
    params: ImmutableMethodParams.ImmutableGetUserParamsTS,
  ): Promise<boolean> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetUserParamsCodec.decode,
      this.isRegisteredF,
    );
  }

  public isRegisteredStarkF(starkPublicKey: string): T.Task<boolean> {
    return pipe(
      this.wallet.getEthKey(this.contractAddress, starkPublicKey),
      TE.fold(
        () => T.of(false),
        () => T.of(true),
      ),
    );
  }

  public async isRegisteredStark(starkPublicKey: string): Promise<boolean> {
    return this.isRegisteredStarkF(starkPublicKey)();
  }

  public registerF(
    params: ImmutableMethodParams.ImmutableRegisterParams,
  ): TE.TaskEither<Error, string> {
    return pipe(
      this.registerImxF(params),
      TE.map(result => result.tx_hash),
    );
  }

  public async register(
    params: ImmutableMethodParams.ImmutableRegisterParamsTS,
  ) {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableRegisterParamsCodec.decode,
      this.registerF,
    );
  }

  public registerStarkF({
    etherKey,
    starkPublicKey,
    operatorSignature,
  }: ImmutableMethodParams.ImmutableStarkRegisterParams): TE.TaskEither<
    Error,
    string
  > {
    return pipe(
      this.wallet.controller.register({
        contractAddress: this.contractAddress,
        etherKey,
        starkPublicKey,
        operatorSignature,
      }),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public async registerStark(
    params: ImmutableMethodParams.ImmutableStarkRegisterParamsTS,
  ) {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableStarkRegisterParamsCodec.decode,
      this.registerStarkF,
    );
  }

  public getSignableRegistrationF({
    etherKey,
    starkPublicKey,
  }: ImmutableMethodParams.ImmutableGetSignableRegistrationParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetSignableRegistrationResult
  > {
    const data = JSON.stringify({
      ether_key: etherKey,
      stark_key: starkPublicKey,
    });
    return this.post(
      'signable-registration',
      data,
      ImmutableMethodResults.ImmutableGetSignableRegistrationResultCodec.decode,
    );
  }

  public async getSignableRegistration(
    params: ImmutableMethodParams.ImmutableGetSignableRegistrationParamsTS,
  ) {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableRegistrationParamsCodec.decode,
      this.getSignableRegistrationF,
    );
  }

  public mintNFTF({
    tokenAddress,
  }: ImmutableMethodParams.ImmutableMintNFTParams): TE.TaskEither<
    Error,
    string
  > {
    return pipe(
      this.wallet.controller.mintNFT(tokenAddress),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public async mintNFT(
    params: ImmutableMethodParams.ImmutableMintNFTParamsTS,
  ): Promise<string> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableMintNFTParamsCodec.decode,
      this.mintNFTF,
    );
  }

  public approveNFTF(
    params: ImmutableMethodParams.ImmutableStarkApproveNFTParams,
  ): TE.TaskEither<Error, string> {
    return pipe(
      this.wallet.controller.approveNFT({
        ...params,
        contractAddress: this.contractAddress,
      }),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public async approveNFT(
    params: ImmutableMethodParams.ImmutableStarkApproveNFTParamsTS,
  ): Promise<string> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableStarkApproveNFTParamsCodec.decode,
      this.approveNFTF,
    );
  }

  public approveERC20F(
    params: ImmutableMethodParams.ImmutableStarkApproveERC20Params,
  ): TE.TaskEither<Error, string> {
    return pipe(
      this.wallet.controller.approveERC20({
        ...params,
        contractAddress: this.contractAddress,
      }),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public async approveERC20(
    params: ImmutableMethodParams.ImmutableStarkApproveERC20ParamsTS,
  ): Promise<string> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableStarkApproveERC20ParamsCodec.decode,
      this.approveERC20F,
    );
  }

  public depositF({
    user,
    token,
    quantity,
  }: ImmutableMethodParams.ImmutableGetSignableDepositParams): TE.TaskEither<
    Error,
    string
  > {
    const data = JSON.stringify({
      user: user.toLowerCase(),
      token: {
        type: token.type,
        data: this.getTokenBody(token),
      },
      amount: quantity.toString(),
    });

    return pipe(
      TE.bindTo('signableDepositResult')(
        this.post(
          'signable-deposit-details',
          data,
          ImmutableMethodResults.ImmutableGetSignableDepositResultCodec.decode,
        ),
      ),
      TE.bind('isRegistered', () =>
        TE.fromTask(this.isRegisteredStarkF(this.starkPublicKey)),
      ),
      TE.chain(({ isRegistered, signableDepositResult }) =>
        isRegistered
          ? pipe(
              StarkMethodParams.StarkDepositParamsCodec.decode({
                contractAddress: this.contractAddress,
                starkPublicKey: signableDepositResult.stark_key,
                assetId: signableDepositResult.asset_id,
                quantity,
                quantizedAmount: signableDepositResult.amount,
                token,
                vaultId: signableDepositResult.vault_id.toString(),
              }),
              E.mapLeft(errorsToError),
              TE.fromEither,
              TE.chain(this.wallet.controller.depositF),
            )
          : token.type === ETHTokenType.ETH // only eth can register and deposit in 1 step
          ? pipe(
              this.getSignableRegistrationF({
                etherKey: user,
                starkPublicKey: this.starkPublicKey,
              }),
              TE.chain(signableRegistrationResult =>
                pipe(
                  RegistrationMethodParams.RegisterAndDepositParamsCodec.decode(
                    {
                      registrationContractAddress:
                        this.registrationContractAddress,
                      starkPublicKey: signableDepositResult.stark_key,
                      assetId: signableDepositResult.asset_id,
                      quantity,
                      quantizedAmount: signableDepositResult.amount,
                      token,
                      vaultId: signableDepositResult.vault_id.toString(),
                      etherKey: user,
                      operatorSignature:
                        signableRegistrationResult.operator_signature,
                    },
                  ),
                  E.mapLeft(errorsToError),
                  TE.fromEither,
                  TE.chain(this.wallet.controller.registerAndDepositF),
                ),
              ),
            )
          : TE.left(new Error('User unregistered')),
      ),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public async deposit(
    params: ImmutableMethodParams.ImmutableGetSignableDepositParamsTS,
  ): Promise<string> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableDepositParamsCodec.decode,
      this.depositF,
    );
  }

  public async depositCancel(
    starkPublicKey: string,
    token: Token,
    vaultId: string,
  ) {
    const assetID = getAssetID(token).val;
    const nonce = parseInt(
      (await this.getLastAvailableNonce(starkPublicKey)).Nonce,
    );
    const body = {
      stark_key: starkPublicKey,
      asset_id: assetID,
      vault_id: parseInt(vaultId),
      nonce,
    };
    const signature = await this.wallet.controller.signStark(body);
    const data = JSON.stringify({
      ...body,
      stark_signature: signature,
    });

    try {
      const res = await axios.post('cancel-deposit', data, this.buildOptions());
      return res.data;
    } catch (err) {
      throw formatError(err);
    }
  }

  public async depositReclaim(
    starkPublicKey: string,
    token: Token,
    vaultId: string,
  ) {
    const assetID = getAssetID(token).val;
    const nonce = parseInt(
      (await this.getLastAvailableNonce(starkPublicKey)).Nonce,
    );
    const body = {
      stark_key: starkPublicKey,
      asset_id: assetID,
      vault_id: parseInt(vaultId),
      nonce,
    };
    const signature = await this.wallet.controller.signStark(body);
    const data = JSON.stringify({
      ...body,
      stark_signature: signature,
    });

    try {
      const res = await axios.post(
        'reclaim-deposit',
        data,
        this.buildOptions(),
      );
      return res.data;
    } catch (err) {
      throw formatError(err);
    }
  }

  public prepareWithdrawalF({
    user,
    token,
    quantity,
  }: ImmutableMethodParams.ImmutablePrepareWithdrawalParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableWithdrawalResult
  > {
    return pipe(
      TE.bindTo('signableWithdrawalResult')(
        this.post(
          'signable-withdrawal-details',
          JSON.stringify({
            user: user.toLowerCase(),
            token: {
              type:
                token.type.toUpperCase() === 'MINTABLE_ERC721'
                  ? ERC721TokenType.ERC721
                  : token.type,
              data: this.getTokenBody(token),
            },
            amount: quantity.toString(), // unquantized amount
          }),
          ImmutableMethodResults.ImmutableGetSignableWithdrawalResultCodec
            .decode,
        ),
      ),
      TE.bind('signature', ({ signableWithdrawalResult }) =>
        this.wallet.controller.signWithdraw(
          signableWithdrawalResult.stark_key,
          String(signableWithdrawalResult.vault_id),
          token,
          signableWithdrawalResult.asset_id,
          signableWithdrawalResult.amount, // quantized amount
          String(signableWithdrawalResult.nonce),
        ),
      ),
      TE.bind('ethSignature', ({ signableWithdrawalResult }) =>
        this.signEthMessage(signableWithdrawalResult.signable_message),
      ),
      TE.bind(
        'result',
        ({ signableWithdrawalResult, signature, ethSignature }) =>
          this.post(
            'withdrawals',
            JSON.stringify({
              stark_key: signableWithdrawalResult.stark_key,
              amount: quantity.toString(), // unquantized amount
              asset_id: signableWithdrawalResult.asset_id,
              vault_id: signableWithdrawalResult.vault_id,
              nonce: signableWithdrawalResult.nonce,
              stark_signature: signature,
            }),
            ImmutableMethodResults.ImmutableWithdrawalResultCodec.decode,
            '',
            addEthHeaders(
              {},
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          ),
      ),
      TE.map(({ result }) => result),
    );
  }

  public prepareWithdrawal(
    params: ImmutableMethodParams.ImmutablePrepareWithdrawalParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableWithdrawalResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutablePrepareWithdrawalParamsCodec.decode,
      this.prepareWithdrawalF,
    );
  }

  public processMintableToken(
    mintableToken: ImmutableMethodResults.ImmutableGetMintableTokenResult,
    token: ERC721Token,
  ): MintableERC721Token {
    return {
      type: MintableERC721TokenType.MINTABLE_ERC721,
      data: {
        id: mintableToken.client_token_id, // link.id  == client_token_id
        blueprint: mintableToken.blueprint,
        tokenAddress: token.data.tokenAddress,
      },
    };
  }

  public completeWithdrawalF({
    starkPublicKey,
    token,
  }: ImmutableMethodParams.ImmutableCompleteWithdrawalParams): TE.TaskEither<
    Error,
    string
  > {
    return pipe(
      TE.bindTo('processedToken')(
        TE.fromTask<Error, Token>(
          token.type === ERC721TokenType.ERC721
            ? pipe(
                this.getMintableTokenF({
                  tokenAddress: token.data.tokenAddress,
                  tokenId: token.data.tokenId,
                }),
                TE.fold<
                  Error,
                  ImmutableMethodResults.ImmutableGetMintableTokenResult,
                  Token
                >(
                  _e => T.of(token),
                  mintableToken =>
                    T.of(this.processMintableToken(mintableToken, token)),
                ),
              )
            : T.of(token),
        ),
      ),
      TE.bind('isRegistered', () =>
        TE.fromTask(this.isRegisteredStarkF(starkPublicKey)),
      ),
      TE.chain(({ isRegistered, processedToken }) =>
        isRegistered
          ? pipe(
              this.wallet.controller.withdrawal(
                this.contractAddress,
                starkPublicKey,
                processedToken,
              ),
            )
          : pipe(
              this.getSignableRegistrationF({
                etherKey: this.address,
                starkPublicKey: this.starkPublicKey,
              }),
              TE.chain(signableRegistrationResult =>
                processedToken.type === ETHTokenType.ETH // only eth can register and withdraw in 1 step
                  ? this.wallet.controller.registerAndWithdraw({
                      registrationContractAddress:
                        this.registrationContractAddress,
                      starkPublicKey,
                      token: processedToken,
                      etherKey: this.address,
                      operatorSignature:
                        signableRegistrationResult.operator_signature,
                    })
                  : TE.left(new Error('User unregistered')),
              ),
            ),
      ),
      TE.chain(this.wallet.sendTransactionF),
    );
  }

  public completeWithdrawal(
    params: ImmutableMethodParams.ImmutableCompleteWithdrawalParamsTS,
  ): Promise<string> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableCompleteWithdrawalParamsCodec.decode,
      this.completeWithdrawalF,
    );
  }

  public transferF({
    sender,
    token,
    quantity,
    receiver,
  }: ImmutableMethodParams.ImmutableTransferParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableTransferResult
  > {
    return pipe(
      TE.bindTo('signableTransferResult')(
        this.post(
          'signable-transfer-details',
          JSON.stringify({
            sender: sender.toLowerCase(),
            token: {
              type: token.type,
              data: this.getTokenBody(token),
            },
            amount:
              token.type === ERC721TokenType.ERC721 ? '1' : quantity.toString(),
            receiver: receiver.toLowerCase(),
          }),
          ImmutableMethodResults.ImmutableGetSignableTransferResultCodec.decode,
        ),
      ),
      TE.bind('signature', ({ signableTransferResult }) => {
        return this.wallet.controller.transfer(
          {
            starkPublicKey: signableTransferResult.sender_stark_key,
            vaultId: signableTransferResult.sender_vault_id,
          },
          {
            starkPublicKey: signableTransferResult.receiver_stark_key,
            vaultId: signableTransferResult.receiver_vault_id,
          },
          token,
          signableTransferResult.asset_id,
          signableTransferResult.amount,
          String(signableTransferResult.nonce),
          String(signableTransferResult.expiration_timestamp),
        );
      }),
      TE.bind('ethSignature', ({ signableTransferResult }) =>
        this.signEthMessage(signableTransferResult.signable_message),
      ),
      TE.bind('result', ({ signableTransferResult, signature, ethSignature }) =>
        this.post(
          'transfers',
          JSON.stringify({
            sender_stark_key: signableTransferResult.sender_stark_key,
            sender_vault_id: signableTransferResult.sender_vault_id,
            receiver_stark_key: signableTransferResult.receiver_stark_key,
            receiver_vault_id: signableTransferResult.receiver_vault_id,
            amount: signableTransferResult.amount.toString(),
            asset_id: signableTransferResult.asset_id,
            expiration_timestamp: signableTransferResult.expiration_timestamp,
            nonce: signableTransferResult.nonce,
            stark_signature: signature,
          }),
          ImmutableMethodResults.ImmutableTransferResultCodec.decode,
          '',
          addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
        ),
      ),
      TE.map(({ result }) => result),
    );
  }

  public transfer(
    params: ImmutableMethodParams.ImmutableTransferParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableTransferResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableTransferParamsCodec.decode,
      this.transferF,
    );
  }

  public burnF({
    sender,
    token,
    quantity,
  }: ImmutableMethodParams.ImmutableBurnParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableBurnResult
  > {
    return pipe(
      TE.bindTo('signableTransferResult')(
        this.post(
          'signable-transfer-details',
          JSON.stringify({
            sender: sender.toLowerCase(),
            token: {
              type: token.type,
              data: this.getTokenBody(token),
            },
            amount:
              token.type === ERC721TokenType.ERC721 ? '1' : quantity.toString(),
            receiver: BurnAddress.BurnEthAddress,
          }),
          ImmutableMethodResults.ImmutableGetSignableTransferResultCodec.decode,
        ),
      ),
      TE.bind('signature', ({ signableTransferResult }) => {
        return this.wallet.controller.transfer(
          {
            starkPublicKey: signableTransferResult.sender_stark_key,
            vaultId: signableTransferResult.sender_vault_id,
          },
          {
            starkPublicKey: signableTransferResult.receiver_stark_key,
            vaultId: signableTransferResult.receiver_vault_id,
          },
          token,
          signableTransferResult.asset_id,
          signableTransferResult.amount,
          String(signableTransferResult.nonce),
          String(signableTransferResult.expiration_timestamp),
        );
      }),
      TE.bind('ethSignature', ({ signableTransferResult }) => {
        return this.signEthMessage(signableTransferResult.signable_message);
      }),
      TE.bind('result', ({ signableTransferResult, signature, ethSignature }) =>
        this.post(
          'transfers',
          JSON.stringify({
            sender_stark_key: signableTransferResult.sender_stark_key,
            sender_vault_id: signableTransferResult.sender_vault_id,
            receiver_stark_key: signableTransferResult.receiver_stark_key,
            receiver_vault_id: signableTransferResult.receiver_vault_id,
            amount: signableTransferResult.amount.toString(),
            asset_id: signableTransferResult.asset_id,
            expiration_timestamp: signableTransferResult.expiration_timestamp,
            nonce: signableTransferResult.nonce,
            stark_signature: signature,
          }),
          ImmutableMethodResults.ImmutableTransferResultCodec.decode,
          '',
          addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
        ),
      ),
      TE.map(({ result }) => result),
    );
  }

  public burn(
    params: ImmutableMethodParams.ImmutableBurnParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableBurnResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableBurnParamsCodec.decode,
      this.burnF,
    );
  }

  public signMessage(
    message: ImmutableMethodParams.ImmutableSignParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableSignResult> {
    return pipe(
      message,
      TE.of,
      TE.bind('ethSignature', () =>
        taskEitherWithError(() => this.wallet.controller.signRaw(message)),
      ),
    );
  }

  public mintF(
    params: ImmutableMethodParams.ImmutableOffchainMintParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableOffchainMintResults> {
    console.warn(
      'mint will be deprecated. Please prepare for mint v2 containing fee fields',
    );
    return pipe(
      params.mints,
      A.traverse(TE.taskEither)((mint: MintBody) =>
        pipe(
          mint.tokens,
          A.map((token: MintableERC721Token) => ({
            type: ERC721TokenType.ERC721,
            data: {
              id: token.data.id,
              blueprint: token.data.blueprint,
              token_address: token.data.tokenAddress,
            },
          })),
          tokens => ({
            result: {
              ether_key: mint.etherKey.toLowerCase(),
              tokens,
              nonce: parseInt(mint.nonce),
              auth_signature: '',
            },
          }),
          TE.of,
          TE.bind('auth_signature', ({ result }) =>
            taskEitherWithError(() => this.wallet.controller.sign(result)),
          ),
          TE.map(({ result, auth_signature }) => ({
            user: result.ether_key,
            tokens: result.tokens,
            nonce: parseInt(mint.nonce),
            auth_signature,
          })),
        ),
      ),
      TE.chain(mints =>
        this.post(
          'mints',
          JSON.stringify({
            mints,
          }),
          ImmutableMethodResults.ImmutableOffchainMintResultsCodec.decode,
        ),
      ),
    );
  }

  public mint(
    params: ImmutableMethodParams.ImmutableOffchainMintParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableOffchainMintResults> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableOffchainMintParamsCodec.decode,
      this.mintF,
    );
  }

  // TODO: remove V2 label when V1 is deprecated
  public mintV2F(
    params: ImmutableMethodParams.ImmutableOffchainMintV2Params,
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableOffchainMintV2Results
  > {
    return pipe(
      params,
      A.traverse(TE.taskEither)((mint: MintV2Body) =>
        pipe(
          mint.users,
          // reshape the data to be the same shape as in imx-engine for auth_signature
          A.map(user => ({
            ether_key: user.etherKey.toLowerCase(),
            tokens: user.tokens.map(token => ({
              id: token.id,
              blueprint: token.blueprint,
              royalties: token.royalties?.map(fee => ({
                recipient: fee.recipient.toLowerCase(),
                percentage: fee.percentage,
              })),
            })),
          })),
          // ensure that the order of the payload to be signed is the same as engine
          users => ({
            result: {
              contract_address: mint.contractAddress,
              royalties: mint.royalties?.map(fee => ({
                recipient: fee.recipient,
                percentage: fee.percentage,
              })),
              users,
              auth_signature: '',
            },
          }),
          TE.of,
          TE.bind('auth_signature', ({ result }) =>
            taskEitherWithError(() => this.wallet.controller.sign(result)),
          ),
          TE.map(({ result, auth_signature }) => ({
            // same shape as public api
            users: result.users.map(user => ({
              user: user.ether_key,
              tokens: user.tokens,
            })),
            royalties: mint.royalties,
            contract_address: mint.contractAddress,
            auth_signature,
          })),
        ),
      ),
      TE.chain(mints =>
        this.post(
          'mints',
          JSON.stringify(mints),
          ImmutableMethodResults.ImmutableOffchainMintV2ResultsCodec.decode,
          'v2',
        ),
      ),
    );
  }

  // TODO: remove V2 label when V1 is deprecated
  public mintV2(
    params: ImmutableMethodParams.ImmutableOffchainMintV2ParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableOffchainMintV2Results> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableOffchainMintV2ParamsCodec.decode,
      this.mintV2F,
    );
  }

  // REMOVE IN https://immutable.atlassian.net/browse/IMX-4526
  public mintV2Testing(
    params: ImmutableMethodParams.ImmutableOffchainMintV2ParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableOffchainMintV2Results> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableOffchainMintV2ParamsCodec.decode,
      this.mintV2F,
    );
  }

  public calculateMakerOrderAmountsF(
    params: ImmutableMethodParams.ImmutableGetSignableOrderParams,
  ): TE.TaskEither<
    Error,
    {
      amountSell: PositiveBigNumber;
      amountBuy: PositiveBigNumber;
    }
  > {
    let nftToken;
    let baseAmount;

    if (params.tokenSell.type === ERC721TokenType.ERC721) {
      nftToken = params.tokenSell;
      baseAmount = params.amountBuy;
    } else if (params.tokenBuy.type === ERC721TokenType.ERC721) {
      nftToken = params.tokenBuy;
      baseAmount = params.amountSell;
    } else {
      return TE.of({
        amountSell: params.amountSell,
        amountBuy: params.amountBuy,
      });
    }

    const { fees: makerFees } = params;

    const decimals = 2;
    const multiplier = Math.pow(10, decimals);

    return pipe(
      this.getAssetF({
        address: nftToken.data.tokenAddress,
        id: nftToken.data.tokenId,
        include_fees: true,
      }),
      TE.bind('totalFeeAmount', ({ fees: royaltyFees }) => {
        let totalFeeAmount = BigNumber.from(0);

        const fees: FeeType[] = [];

        if (royaltyFees?.length) {
          fees.push(
            ...royaltyFees.map(fee => ({
              recipient: fee.address,
              percentage: fee.percentage,
            })),
          );
        }

        if (makerFees?.length) {
          fees.push(...makerFees);
        }

        fees?.forEach(fee => {
          // toFixed needed to prevent floating point number precision issues.
          // e.g. 4.56 * 100 = 455.99999999999994 in JS
          const feeAmount = Math.floor(
            parseFloat((fee.percentage * multiplier).toFixed(decimals)),
          ).toFixed();
          totalFeeAmount = totalFeeAmount.add(
            BigNumber.from(feeAmount).mul(baseAmount),
          );
        });

        return TE.of(totalFeeAmount.div(100 * multiplier));
      }),
      TE.map(({ totalFeeAmount }) => {
        let { amountSell } = params;
        let { amountBuy } = params;

        if (params.tokenSell.type === ERC721TokenType.ERC721) {
          amountBuy = valueOrThrow(
            PositiveBigNumber.decode(amountBuy.add(totalFeeAmount)),
          );
        } else {
          amountSell = valueOrThrow(
            PositiveBigNumber.decode(amountSell.add(totalFeeAmount)),
          );
        }

        return {
          amountSell,
          amountBuy,
        };
      }),
    );
  }

  /**
   * @experimental - This function is only available for alpha testing and may change
   */
  public createTradeRequestV3F({
    user,
    orderId,
    fees,
    expiration_timestamp,
  }: ImmutableMethodParams.ImmutableGetSignableTradeV3Params): TE.TaskEither<
    Error,
    {
      signableOrderResult: ImmutableMethodResults.ImmutableGetSignableOrderResult;
      signature: string;
    }
  > {
    return pipe(
      TE.bindTo('signableOrderResult')(
        this.post(
          'signable-trade-details',
          JSON.stringify({
            user: user.toLowerCase(),
            order_id: orderId,
            fees: this.formatFeesForRequest(fees),
            expiration_timestamp,
          }),
          ImmutableMethodResults.ImmutableGetSignableOrderResultCodec.decode,
          'v3',
        ),
      ),
      TE.bind('feeInfo', ({ signableOrderResult }) =>
        signableOrderResult.fee_info
          ? TE.of({
              feeToken:
                signableOrderResult.fee_info.asset_id ===
                signableOrderResult.asset_id_sell
                  ? signableOrderResult.asset_id_sell
                  : signableOrderResult.asset_id_buy,
              feeVaultId: signableOrderResult.fee_info.source_vault_id,
              feeLimit: signableOrderResult.fee_info.fee_limit,
            })
          : TE.of(null),
      ),
      TE.bind('signature', ({ signableOrderResult, feeInfo }) =>
        feeInfo
          ? // TODO: implement fees flow here
            this.wallet.controller.createOrderV3(
              signableOrderResult.stark_key,
              signableOrderResult.vault_id_sell,
              signableOrderResult.vault_id_buy,
              signableOrderResult.amount_sell,
              signableOrderResult.amount_buy,
              signableOrderResult.asset_id_sell,
              signableOrderResult.asset_id_buy,
              signableOrderResult.nonce,
              signableOrderResult.expiration_timestamp,
            )
          : this.wallet.controller.createOrderV3(
              signableOrderResult.stark_key,
              signableOrderResult.vault_id_sell,
              signableOrderResult.vault_id_buy,
              signableOrderResult.amount_sell,
              signableOrderResult.amount_buy,
              signableOrderResult.asset_id_sell,
              signableOrderResult.asset_id_buy,
              signableOrderResult.nonce,
              signableOrderResult.expiration_timestamp,
            ),
      ),
      TE.map(({ signableOrderResult, signature }) => ({
        signableOrderResult,
        signature,
      })),
    );
  }

  public createOrderRequestV3F({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    include_fees = true,
    fees,
    expiration_timestamp,
  }: ImmutableMethodParams.ImmutableGetSignableOrderParams): TE.TaskEither<
    Error,
    {
      signableOrderResult: ImmutableMethodResults.ImmutableGetSignableOrderResult;
      sellParams: OrderParams;
      buyParams: OrderParams;
      signature: string;
    }
  > {
    return pipe(
      TE.bindTo('signableOrderResult')(
        this.post(
          'signable-order-details',
          JSON.stringify({
            user: user.toLowerCase(),
            token_sell: {
              type: tokenSell.type,
              data: this.getTokenBody(tokenSell),
            },
            amount_sell: amountSell.toString(),
            token_buy: {
              type: tokenBuy.type,
              data: this.getTokenBody(tokenBuy),
            },
            amount_buy: amountBuy.toString(),
            fees: this.formatFeesForRequest(fees),
            expiration_timestamp,
          }),
          ImmutableMethodResults.ImmutableGetSignableOrderResultCodec.decode,
          'v3',
        ),
      ),
      TE.bind('feeInfo', ({ signableOrderResult }) =>
        signableOrderResult.fee_info
          ? TE.of({
              feeToken:
                signableOrderResult.fee_info.asset_id ===
                signableOrderResult.asset_id_sell
                  ? signableOrderResult.asset_id_sell
                  : signableOrderResult.asset_id_buy,
              feeVaultId: signableOrderResult.fee_info.source_vault_id,
              feeLimit: signableOrderResult.fee_info.fee_limit,
            })
          : TE.of(null),
      ),
      TE.bind('sellParams', ({ signableOrderResult }) => {
        const amountSell = signableOrderResult.amount_sell;
        return TE.of({
          vaultId: signableOrderResult.vault_id_sell,
          token: tokenSell,
          quantity: amountSell,
        });
      }),
      TE.bind('buyParams', ({ signableOrderResult }) => {
        const amountBuy = signableOrderResult.amount_buy;
        return TE.of({
          vaultId: signableOrderResult.vault_id_buy,
          token: tokenBuy,
          quantity: amountBuy,
        });
      }),
      TE.bind(
        'signature',
        ({ signableOrderResult, sellParams, buyParams, feeInfo }) =>
          feeInfo && buyParams.token.type === ERC721TokenType.ERC721
            ? this.wallet.controller.createOrderWithFee(
                signableOrderResult.stark_key,
                sellParams,
                buyParams,
                signableOrderResult.asset_id_sell,
                signableOrderResult.asset_id_buy,
                signableOrderResult.nonce,
                signableOrderResult.expiration_timestamp,
                feeInfo,
              )
            : this.wallet.controller.createOrder(
                signableOrderResult.stark_key,
                sellParams,
                buyParams,
                signableOrderResult.asset_id_sell,
                signableOrderResult.asset_id_buy,
                signableOrderResult.nonce,
                signableOrderResult.expiration_timestamp,
              ),
      ),
      TE.map(({ signableOrderResult, sellParams, buyParams, signature }) => ({
        signableOrderResult,
        sellParams,
        buyParams,
        signature,
      })),
    );
  }

  public createOrderRequestF({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    include_fees = true,
    fees,
    expiration_timestamp,
  }: ImmutableMethodParams.ImmutableGetSignableOrderParams): TE.TaskEither<
    Error,
    {
      signableOrderResult: ImmutableMethodResults.ImmutableGetSignableOrderResult;
      sellParams: OrderParams;
      buyParams: OrderParams;
      signature: string;
    }
  > {
    return pipe(
      TE.bindTo('signableOrderResult')(
        this.post(
          'signable-order-details',
          JSON.stringify({
            user: user.toLowerCase(),
            token_sell: {
              type: tokenSell.type,
              data: this.getTokenBody(tokenSell),
            },
            amount_sell: amountSell.toString(),
            token_buy: {
              type: tokenBuy.type,
              data: this.getTokenBody(tokenBuy),
            },
            amount_buy: amountBuy.toString(),
            include_fees,
            fees: this.formatFeesForRequest(fees),
            expiration_timestamp,
          }),
          ImmutableMethodResults.ImmutableGetSignableOrderResultCodec.decode,
        ),
      ),
      TE.bind('feeInfo', ({ signableOrderResult }) =>
        signableOrderResult.fee_info
          ? TE.of({
              feeToken:
                signableOrderResult.fee_info.asset_id ===
                signableOrderResult.asset_id_sell
                  ? signableOrderResult.asset_id_sell
                  : signableOrderResult.asset_id_buy,
              feeVaultId: signableOrderResult.fee_info.source_vault_id,
              feeLimit: signableOrderResult.fee_info.fee_limit,
            })
          : TE.of(null),
      ),
      TE.bind('sellParams', ({ signableOrderResult }) => {
        const amountSell = include_fees
          ? signableOrderResult.amount_sell
          : tokenQuantizedAmount(tokenSell, signableOrderResult.amount_sell);
        return TE.of({
          vaultId: signableOrderResult.vault_id_sell,
          token: tokenSell,
          quantity: amountSell,
        });
      }),
      TE.bind('buyParams', ({ signableOrderResult }) => {
        const amountBuy = include_fees
          ? signableOrderResult.amount_buy
          : tokenQuantizedAmount(tokenBuy, signableOrderResult.amount_buy);
        return TE.of({
          vaultId: signableOrderResult.vault_id_buy,
          token: tokenBuy,
          quantity: amountBuy,
        });
      }),
      TE.bind(
        'signature',
        ({ signableOrderResult, sellParams, buyParams, feeInfo }) =>
          include_fees &&
          feeInfo &&
          buyParams.token.type === ERC721TokenType.ERC721
            ? this.wallet.controller.createOrderWithFee(
                signableOrderResult.stark_key,
                sellParams,
                buyParams,
                signableOrderResult.asset_id_sell,
                signableOrderResult.asset_id_buy,
                signableOrderResult.nonce,
                signableOrderResult.expiration_timestamp,
                feeInfo,
              )
            : this.wallet.controller.createOrder(
                signableOrderResult.stark_key,
                sellParams,
                buyParams,
                signableOrderResult.asset_id_sell,
                signableOrderResult.asset_id_buy,
                signableOrderResult.nonce,
                signableOrderResult.expiration_timestamp,
              ),
      ),
      TE.map(({ signableOrderResult, sellParams, buyParams, signature }) => ({
        signableOrderResult,
        sellParams,
        buyParams,
        signature,
      })),
    );
  }

  private formatFeesForRequest(
    fees: FeeType[] | undefined,
  ): { fee_percentage: number; address: string }[] | undefined {
    return fees?.map(fee => ({
      address: fee.recipient,
      fee_percentage: fee.percentage,
    }));
  }

  private formatAuxiliaryFeesForQueryString(
    auxiliaryFees: FeeType[] | undefined,
  ):
    | {
        auxiliary_fee_recipients: string;
        auxiliary_fee_percentages: string;
      }
    | undefined {
    if (!auxiliaryFees?.length) return undefined;

    return {
      auxiliary_fee_recipients: auxiliaryFees
        .map(fee => fee.recipient)
        .join(','),
      auxiliary_fee_percentages: auxiliaryFees
        .map(fee => fee.percentage)
        .join(','),
    };
  }

  public createOrderF({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    include_fees = true,
    fees,
    expiration_timestamp,
  }: ImmutableMethodParams.ImmutableGetSignableOrderParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCreateOrderResult
  > {
    return pipe(
      include_fees
        ? this.calculateMakerOrderAmountsF({
            user,
            tokenSell,
            tokenBuy,
            amountSell,
            amountBuy,
            include_fees,
            fees,
          })
        : TE.of({ amountSell, amountBuy }),
      TE.chain(feeInclusiveAmounts =>
        this.createOrderRequestF({
          user,
          tokenSell,
          tokenBuy,
          amountSell: feeInclusiveAmounts.amountSell,
          amountBuy: feeInclusiveAmounts.amountBuy,
          include_fees,
          fees,
          expiration_timestamp,
        }),
      ),
      TE.bind('ethSignature', ({ signableOrderResult }) => {
        return this.signEthMessage(signableOrderResult.signable_message);
      }),
      TE.bind(
        'result',
        ({
          signableOrderResult,
          sellParams,
          buyParams,
          signature,
          ethSignature,
        }) =>
          this.post(
            'orders',
            JSON.stringify({
              stark_key: signableOrderResult.stark_key,
              amount_sell: signableOrderResult.amount_sell.toString(),
              asset_id_sell: signableOrderResult.asset_id_sell,
              vault_id_sell: sellParams.vaultId,
              amount_buy: signableOrderResult.amount_buy.toString(),
              asset_id_buy: signableOrderResult.asset_id_buy,
              vault_id_buy: buyParams.vaultId,
              expiration_timestamp: signableOrderResult.expiration_timestamp,
              nonce: signableOrderResult.nonce,
              stark_signature: signature,
              include_fees,
              fees: this.formatFeesForRequest(fees),
            }),
            ImmutableMethodResults.ImmutableCreateOrderResultCodec.decode,
            '',
            addEthHeaders(
              {},
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          ),
      ),
      TE.map(({ result }) => result),
    );
  }

  public createOrder(
    params: ImmutableMethodParams.ImmutableGetSignableOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateOrderResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableOrderParamsCodec.decode,
      this.createOrderF,
    );
  }

  public createOrderV3F({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    include_fees = true,
    fees,
    expiration_timestamp,
  }: ImmutableMethodParams.ImmutableGetSignableOrderParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCreateOrderResult
  > {
    return pipe(
      include_fees
        ? this.calculateMakerOrderAmountsF({
            user,
            tokenSell,
            tokenBuy,
            amountSell,
            amountBuy,
            include_fees,
            fees,
          })
        : TE.of({ amountSell, amountBuy }),
      TE.chain(_ =>
        this.createOrderRequestV3F({
          user,
          tokenSell,
          tokenBuy,
          amountSell, // feeInclusiveAmounts.amountSell,
          amountBuy, // feeInclusiveAmounts.amountBuy,
          include_fees,
          fees,
          expiration_timestamp,
        }),
      ),
      TE.bind('ethSignature', ({ signableOrderResult }) => {
        return this.signEthMessage(signableOrderResult.signable_message);
      }),
      TE.bind(
        'result',
        ({
          signableOrderResult,
          sellParams,
          buyParams,
          signature,
          ethSignature,
        }) =>
          this.post(
            'orders',
            JSON.stringify({
              stark_key: signableOrderResult.stark_key,
              amount_sell: signableOrderResult.amount_sell.toString(),
              asset_id_sell: signableOrderResult.asset_id_sell,
              vault_id_sell: sellParams.vaultId,
              amount_buy: signableOrderResult.amount_buy.toString(),
              asset_id_buy: signableOrderResult.asset_id_buy,
              vault_id_buy: buyParams.vaultId,
              expiration_timestamp: signableOrderResult.expiration_timestamp,
              nonce: signableOrderResult.nonce,
              stark_signature: signature,
              fees: this.formatFeesForRequest(fees),
            }),
            ImmutableMethodResults.ImmutableCreateOrderResultCodec.decode,
            'v3',
            addEthHeaders(
              {},
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          ),
      ),
      TE.map(({ result }) => result),
    );
  }

  /**
   * @experimental - This function is only available for alpha testing and may change
   */
  public createOrderV3(
    params: ImmutableMethodParams.ImmutableGetSignableOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateOrderResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableOrderParamsCodec.decode,
      this.createOrderV3F,
    );
  }

  public cancelOrderF(
    orderId: ImmutableMethodParams.ImmutableCancelOrderParamsTS,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableCancelOrderResult> {
    return pipe(
      TE.of({ orderId }),
      TE.bind('signature', ({ orderId }) =>
        this.wallet.controller.cancelOrder(orderId.toString()),
      ),
      TE.bind('data', ({ signature }) => {
        return TE.of({
          stark_signature: signature,
        });
      }),
      TE.bind('signableResult', ({ orderId }) =>
        this.post(
          '/signable-cancel-order-details',
          JSON.stringify({ order_id: orderId }),
          ImmutableMethodResults.ImmutableGetSignableCancelOrderResultCodec
            .decode,
        ),
      ),
      TE.bind('ethSignature', ({ signableResult }) =>
        this.signEthMessage(signableResult.signable_message),
      ),
      TE.bind('result', ({ data, ethSignature }) =>
        this.delete(
          `/orders/${orderId}`,
          data,
          ImmutableMethodResults.ImmutableCancelOrderResultCodec.decode,
          addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
        ),
      ),
      TE.map(({ result }) => result),
    );
  }

  public cancelOrder(
    params: ImmutableMethodParams.ImmutableCancelOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCancelOrderResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableCancelOrderParamsCodec.decode,
      this.cancelOrderF,
    );
  }

  public cancelOrderV3F(
    orderId: ImmutableMethodParams.ImmutableCancelOrderParamsTS,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableCancelOrderResult> {
    return pipe(
      TE.of({ orderId }),
      TE.bind('signature', ({ orderId }) =>
        this.wallet.controller.cancelOrder(orderId.toString()),
      ),
      TE.bind('data', ({ signature }) => {
        return TE.of({
          stark_signature: signature,
        });
      }),
      TE.bind('signableResult', ({ orderId }) =>
        this.post(
          '/signable-cancel-order-details',
          JSON.stringify({ order_id: orderId }),
          ImmutableMethodResults.ImmutableGetSignableCancelOrderResultCodec
            .decode,
          'v3',
        ),
      ),
      TE.bind('ethSignature', ({ signableResult }) =>
        this.signEthMessage(signableResult.signable_message),
      ),
      TE.bind('result', ({ data, ethSignature }) =>
        this.delete(
          `/orders/${orderId}`,
          data,
          ImmutableMethodResults.ImmutableCancelOrderResultCodec.decode,
          addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
          'v3',
        ),
      ),
      TE.map(({ result }) => result),
    );
  }

  /**
   * @experimental - This function is only available for alpha testing and may change
   */
  public cancelOrderV3(
    params: ImmutableMethodParams.ImmutableCancelOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCancelOrderResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableCancelOrderParamsCodec.decode,
      this.cancelOrderV3F,
    );
  }

  public createTradeF({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    orderId,
    include_fees = true,
    fees,
  }: ImmutableMethodParams.ImmutableGetSignableTradeParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCreateTradeResult
  > {
    return pipe(
      this.createOrderRequestF({
        user,
        tokenSell,
        tokenBuy,
        amountSell,
        amountBuy,
        include_fees,
        fees,
      }),
      TE.bind('ethSignature', ({ signableOrderResult }) => {
        return this.signEthMessage(signableOrderResult.signable_message);
      }),
      TE.bind(
        'result',
        ({
          signableOrderResult,
          sellParams,
          buyParams,
          signature,
          ethSignature,
        }) => {
          const feeInfo = signableOrderResult.fee_info && {
            ...signableOrderResult.fee_info,
            fee_limit: signableOrderResult.fee_info.fee_limit.toString(),
          };
          return this.post(
            'trades',
            JSON.stringify({
              stark_key: signableOrderResult.stark_key,
              amount_sell: signableOrderResult.amount_sell.toString(),
              asset_id_sell: signableOrderResult.asset_id_sell,
              vault_id_sell: sellParams.vaultId,
              amount_buy: signableOrderResult.amount_buy.toString(),
              asset_id_buy: signableOrderResult.asset_id_buy,
              vault_id_buy: buyParams.vaultId,
              expiration_timestamp: signableOrderResult.expiration_timestamp,
              nonce: signableOrderResult.nonce,
              stark_signature: signature,
              order_id: orderId,
              fee_info: feeInfo,
              include_fees,
              fees: this.formatFeesForRequest(fees),
            }),
            ImmutableMethodResults.ImmutableCreateTradeResultCodec.decode,
            '',
            addEthHeaders(
              {},
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          );
        },
      ),
      TE.map(({ result }) => result),
    );
  }

  public createTrade(
    params: ImmutableMethodParams.ImmutableGetSignableTradeParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateTradeResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableTradeParamsCodec.decode,
      this.createTradeF,
    );
  }

  public createTradeV3F({
    user,
    orderId,
    fees,
  }: ImmutableMethodParams.ImmutableGetSignableTradeV3Params): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCreateTradeResult
  > {
    return pipe(
      this.createTradeRequestV3F({
        user,
        orderId,
        fees,
      }),
      TE.bind('ethSignature', ({ signableOrderResult }) => {
        return this.signEthMessage(signableOrderResult.signable_message);
      }),
      TE.bind('result', ({ signableOrderResult, signature, ethSignature }) => {
        const feeInfo = signableOrderResult.fee_info && {
          ...signableOrderResult.fee_info,
          fee_limit: signableOrderResult.fee_info.fee_limit.toString(),
        };
        return this.post(
          'trades',
          JSON.stringify({
            stark_key: signableOrderResult.stark_key,
            amount_sell: signableOrderResult.amount_sell.toString(),
            asset_id_sell: signableOrderResult.asset_id_sell,
            vault_id_sell: signableOrderResult.vault_id_sell,
            amount_buy: signableOrderResult.amount_buy.toString(),
            asset_id_buy: signableOrderResult.asset_id_buy,
            vault_id_buy: signableOrderResult.vault_id_buy,
            expiration_timestamp: signableOrderResult.expiration_timestamp,
            nonce: signableOrderResult.nonce,
            stark_signature: signature,
            order_id: orderId,
            fee_info: feeInfo,
            // fees: this.formatFeesForRequest(fees),
          }),
          ImmutableMethodResults.ImmutableCreateTradeResultCodec.decode,
          'v3',
          addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
        );
      }),
      TE.map(({ result }) => result),
    );
  }

  /**
   * @experimental - This function is only available for alpha testing and may change
   */
  public createTradeV3(
    params: ImmutableMethodParams.ImmutableGetSignableTradeParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateTradeResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableTradeParamsCodec.decode,
      this.createTradeV3F,
    );
  }

  private createAssessableTradeF({
    user,
    tokenSell,
    tokenBuy,
    amountSell,
    amountBuy,
    orderId,
    include_fees = true,
    fees,
  }: ImmutableMethodParams.ImmutableGetSignableTradeParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCreateTradeResult
  > {
    return pipe(
      this.createOrderRequestF({
        user,
        tokenSell,
        tokenBuy,
        amountSell,
        amountBuy,
        include_fees,
        fees,
      }),
      TE.bind('ethSignature', ({ signableOrderResult }) => {
        return this.signEthMessage(signableOrderResult.signable_message);
      }),
      TE.bind(
        'result',
        ({
          signableOrderResult,
          sellParams,
          buyParams,
          signature,
          ethSignature,
        }) => {
          const feeInfo = signableOrderResult.fee_info && {
            ...signableOrderResult.fee_info,
            fee_limit: signableOrderResult.fee_info.fee_limit.toString(),
          };
          return this.post(
            'trades',
            JSON.stringify({
              stark_key: signableOrderResult.stark_key,
              amount_sell: signableOrderResult.amount_sell.toString(),
              asset_id_sell: signableOrderResult.asset_id_sell,
              vault_id_sell: sellParams.vaultId,
              amount_buy: signableOrderResult.amount_buy.toString(),
              asset_id_buy: signableOrderResult.asset_id_buy,
              vault_id_buy: buyParams.vaultId,
              expiration_timestamp: signableOrderResult.expiration_timestamp,
              nonce: signableOrderResult.nonce,
              stark_signature: signature,
              order_id: orderId,
              fee_info: feeInfo,
              include_fees,
              fees: this.formatFeesForRequest(fees),
            }),
            ImmutableMethodResults.ImmutableCreateTradeResultCodec.decode,
            '',
            addEthHeaders(
              {
                'x-sdk-version': `${RiskAssessmentHeader.WalletSDKPrefix}-${version}`,
              },
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          );
        },
      ),
      TE.map(({ result }) => result),
    );
  }

  private async createAssessableTrade(
    params: ImmutableMethodParams.ImmutableGetSignableTradeParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateTradeResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableTradeParamsCodec.decode,
      this.createAssessableTradeF,
    );
  }

  public getTokenF(
    params: ImmutableMethodParams.ImmutableGetTokenParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetTokenResult> {
    return this.get(
      // NOTE: Only 'eth' can be queried without address!
      `tokens/${params.tokenAddress?.toString() || 'eth'}`,
      ImmutableMethodResults.ImmutableGetTokenResultCodec.decode,
    );
  }

  public async getToken(
    params: ImmutableMethodParams.ImmutableGetTokenParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetTokenResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetTokenParamsCodec.decode,
      this.getTokenF,
    );
  }

  public listTokensF(
    params: ImmutableMethodParams.ImmutableListTokensParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableListTokensResult> {
    const url = queryString.stringifyUrl(
      { url: 'tokens', query: params },
      { arrayFormat: 'comma' },
    );
    return this.get(
      url,
      ImmutableMethodResults.ImmutableListTokensResultCodec.decode,
    );
  }

  public async listTokens(
    params: ImmutableMethodParams.ImmutableListTokensParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableListTokensResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableListTokensParamsCodec.decode,
      this.listTokensF,
    );
  }

  // TODO: remove once v1 balance endpoint is deprecated, this is for backwards compatibility
  public getBalancesF(
    params: ImmutableMethodParams.ImmutableGetBalancesParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetBalancesResult> {
    console.warn(
      'getBalances will be deprecated. Please use listBalances or getBalance to query for token balances',
    );
    return this.get(
      `balances/${params.user.toLowerCase()}`,
      ImmutableMethodResults.ImmutableGetBalancesResultCodec.decode,
    );
  }

  public async getBalances(
    params: ImmutableMethodParams.ImmutableGetBalancesParams,
  ): Promise<ImmutableMethodResults.ImmutableGetBalancesResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetBalancesParamsCodec.decode,
      this.getBalancesF,
    );
  }

  public getBalanceF(
    params: ImmutableMethodParams.ImmutableGetBalanceParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetBalanceResult> {
    return this.get(
      `balances/${params.user.toLowerCase()}/${params.tokenAddress}`,
      ImmutableMethodResults.ImmutableGetBalanceResultCodec.decode,
      'v2',
    );
  }

  public async getBalance(
    params: ImmutableMethodParams.ImmutableGetBalanceParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetBalanceResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetBalanceParamsCodec.decode,
      this.getBalanceF,
    );
  }

  public listBalancesF({
    user,
    symbols,
    cursor,
  }: ImmutableMethodParams.ImmutableListBalancesParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableListBalancesResult
  > {
    const url = queryString.stringifyUrl(
      {
        url: `balances/${user.toLowerCase()}`,
        query: { symbols, cursor },
      },
      {
        arrayFormat: 'comma',
      },
    );
    return this.get(
      url,
      ImmutableMethodResults.ImmutableListBalancesResultCodec.decode,
      'v2',
    );
  }

  public async listBalances(
    params: ImmutableMethodParams.ImmutableListBalancesParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableListBalancesResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableListBalancesParamsCodec.decode,
      this.listBalancesF,
    );
  }

  public getMintableTokenF(
    params: ImmutableMethodParams.ImmutableGetMintableTokenParams,
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetMintableTokenResult
  > {
    return pipe(
      params.tokenId,
      HexadecimalString.decode,
      E.fold(
        _e =>
          this.get(
            `mintable-token/${params.tokenAddress}/${params.tokenId}`,
            ImmutableMethodResults.ImmutableGetMintableTokenResultCodec.decode,
          ),
        _parsedToken =>
          this.get(
            `mintable-token/${params.tokenId}`,
            ImmutableMethodResults.ImmutableGetMintableTokenResultCodec.decode,
          ),
      ),
    );
  }

  public async getMintableToken(
    params: ImmutableMethodParams.ImmutableGetMintableTokenParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetMintableTokenResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetMintableTokenParamsCodec.decode,
      this.getMintableTokenF,
    );
  }

  public async getVaults(starkPublicKey: string) {
    const data = JSON.stringify({
      stark_key: starkPublicKey,
    });

    try {
      const res = await axios.post('get-vaults', data, this.buildOptions());
      return res.data;
    } catch (err) {
      throw formatError(err);
    }
  }

  public getOrderF({
    orderId,
    include_fees = true,
    auxiliaryFees,
  }: ImmutableMethodParams.ImmutableGetOrderParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetOrderResult
  > {
    return this.get(
      queryString.stringifyUrl({
        url: `orders/${orderId}`,
        query: {
          include_fees,
          ...this.formatAuxiliaryFeesForQueryString(auxiliaryFees),
        },
      }),
      ImmutableMethodResults.ImmutableGetOrderResultCodec.decode,
    );
  }

  public getOrder(
    params: ImmutableMethodParams.ImmutableGetOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetOrderResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetOrderParamsCodec.decode,
      this.getOrderF,
    );
  }

  public getOrderV3F({
    orderId,
    include_fees = true,
    auxiliaryFees,
  }: ImmutableMethodParams.ImmutableGetOrderParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetOrderResult
  > {
    return this.get(
      queryString.stringifyUrl({
        url: `orders/${orderId}`,
        query: {
          include_fees,
          ...this.formatAuxiliaryFeesForQueryString(auxiliaryFees),
        },
      }),
      ImmutableMethodResults.ImmutableGetOrderResultCodec.decode,
      'v3',
    );
  }

  public getOrderV3(
    params: ImmutableMethodParams.ImmutableGetOrderParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetOrderResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetOrderParamsCodec.decode,
      this.getOrderV3F,
    );
  }

  public getOrdersF(
    query: ImmutableMethodParams.ImmutableGetOrdersParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetOrdersResult> {
    return this.get(
      queryString.stringifyUrl({
        url: 'orders',
        query: {
          order_by: query.order_by,
          page_size: query.page_size,
          cursor: query.cursor,
          direction: query.direction,
          user: query.user,
          status: query.status,
          min_timestamp: query.min_timestamp,
          max_timestamp: query.max_timestamp,
          buy_token_type: query.buy_token_type,
          buy_token_id: query.buy_token_id,
          buy_token_address: query.buy_token_address,
          buy_token_name: query.buy_token_name,
          buy_min_quantity: query.buy_min_quantity,
          buy_max_quantity: query.buy_max_quantity,
          buy_metadata: query.buy_metadata,
          sell_token_type: query.sell_token_type,
          sell_token_id: query.sell_token_id,
          sell_token_address: query.sell_token_address,
          sell_token_name: query.sell_token_name,
          sell_min_quantity: query.sell_min_quantity,
          sell_max_quantity: query.sell_max_quantity,
          sell_metadata: query.sell_metadata,
          include_fees: query.include_fees,
          ...this.formatAuxiliaryFeesForQueryString(query.auxiliaryFees),
          updated_max_timestamp: query.updated_max_timestamp,
          updated_min_timestamp: query.updated_min_timestamp,
        },
      }),
      ImmutableMethodResults.ImmutableGetOrdersResultCodec.decode,
    );
  }

  public getOrders(
    params: ImmutableMethodParams.ImmutableGetOrdersParamsTS = {
      include_fees: true,
    },
  ): Promise<ImmutableMethodResults.ImmutableGetOrdersResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetOrdersParamsCodec.decode,
      this.getOrdersF,
    );
  }

  public getOrdersV3F(
    query: ImmutableMethodParams.ImmutableGetOrdersParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetOrdersResult> {
    return this.get(
      queryString.stringifyUrl({
        url: 'orders',
        query: {
          order_by: query.order_by,
          page_size: query.page_size,
          cursor: query.cursor,
          direction: query.direction,
          user: query.user,
          status: query.status,
          min_timestamp: query.min_timestamp,
          max_timestamp: query.max_timestamp,
          buy_token_type: query.buy_token_type,
          buy_token_id: query.buy_token_id,
          buy_token_address: query.buy_token_address,
          buy_token_name: query.buy_token_name,
          buy_min_quantity: query.buy_min_quantity,
          buy_max_quantity: query.buy_max_quantity,
          buy_metadata: query.buy_metadata,
          sell_token_type: query.sell_token_type,
          sell_token_id: query.sell_token_id,
          sell_token_address: query.sell_token_address,
          sell_token_name: query.sell_token_name,
          sell_min_quantity: query.sell_min_quantity,
          sell_max_quantity: query.sell_max_quantity,
          sell_metadata: query.sell_metadata,
          include_fees: query.include_fees,
          ...this.formatAuxiliaryFeesForQueryString(query.auxiliaryFees),
          updated_max_timestamp: query.updated_max_timestamp,
          updated_min_timestamp: query.updated_min_timestamp,
        },
      }),
      ImmutableMethodResults.ImmutableGetOrdersResultCodec.decode,
      'v3',
    );
  }

  public getOrdersV3(
    params: ImmutableMethodParams.ImmutableGetOrdersParamsTS = {
      include_fees: true,
    },
  ): Promise<ImmutableMethodResults.ImmutableGetOrdersResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetOrdersParamsCodec.decode,
      this.getOrdersV3F,
    );
  }

  public getAssetsF(
    query: ImmutableMethodParams.ImmutableGetAssetsParams,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetAssetsResult> {
    return this.get(
      queryString.stringifyUrl({
        url: 'assets',
        query: {
          order_by: query.order_by,
          page_size: query.page_size,
          cursor: query.cursor,
          direction: query.direction,
          user: query.user,
          status: query.status,
          metadata: query.metadata,
          collection: query.collection,
          name: query.name,
          sell_orders: query.sell_orders,
          buy_orders: query.buy_orders,
          include_fees: query.include_fees,
          ...this.formatAuxiliaryFeesForQueryString(query.auxiliaryFees),
        },
      }),
      ImmutableMethodResults.ImmutableGetAssetsResultCodec.decode,
      'v1',
    );
  }

  public async getAssets(
    params: ImmutableMethodParams.ImmutableGetAssetsParamsTS = {
      include_fees: true,
    },
  ): Promise<ImmutableMethodResults.ImmutableGetAssetsResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetAssetsParamsCodec.decode,
      this.getAssetsF,
    );
  }

  public getAssetF({
    address,
    id,
    include_fees = true,
  }: ImmutableMethodParams.ImmutableGetAssetParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableAsset
  > {
    return this.get(
      `assets/${address.toLowerCase()}/${id}?include_fees=${include_fees}`,
      ImmutableMethodResults.ImmutableAssetCodec.decode,
      'v1',
    );
  }

  public async getAsset(
    params: ImmutableMethodParams.ImmutableGetAssetParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableAsset> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetAssetParamsCodec.decode,
      this.getAssetF,
    );
  }

  public getApplicationsF(
    query: ImmutableMethodParams.ImmutableGetApplicationsParams = {},
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetApplicationsResult
  > {
    const url = queryString.stringifyUrl({
      url: 'applications',
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetApplicationsResultCodec.decode,
    );
  }

  public getApplications(
    params: ImmutableMethodParams.ImmutableGetApplicationsParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetApplicationsResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetApplicationsParamsCodec.decode,
      this.getApplicationsF,
    );
  }

  public getTradeF({
    id,
  }: ImmutableMethodParams.ImmutableGetTradeParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableTrade
  > {
    return this.get(
      `trades/${id}`,
      ImmutableMethodResults.ImmutableTradeCodec.decode,
    );
  }

  public async getTrade(
    params: ImmutableMethodParams.ImmutableGetTradeParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableTrade> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetTradeParamsCodec.decode,
      this.getTradeF,
    );
  }

  public getCollectionsF(
    query: ImmutableMethodParams.ImmutableGetCollectionsParams,
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetCollectionsResult
  > {
    const url = queryString.stringifyUrl({
      url: `collections`,
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetCollectionsResultCodec.decode,
    );
  }

  public async getCollections(
    params: ImmutableMethodParams.ImmutableGetCollectionsParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetCollectionsResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetCollectionsParamsCodec.decode,
      this.getCollectionsF,
    );
  }

  public getCollectionF({
    address,
  }: ImmutableMethodParams.ImmutableGetCollectionParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableCollection
  > {
    return this.get(
      `collections/${address}`,
      ImmutableMethodResults.ImmutableCollectionCodec.decode,
    );
  }

  public async getCollection(
    params: ImmutableMethodParams.ImmutableGetCollectionParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCollection> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetCollectionParamsCodec.decode,
      this.getCollectionF,
    );
  }

  public getDepositsF(
    query: ImmutableMethodParams.ImmutableGetDepositsParams = {},
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetDepositsResult> {
    const url = queryString.stringifyUrl({
      url: 'deposits',
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetDepositsResultCodec.decode,
    );
  }

  public async getDeposits(
    params: ImmutableMethodParams.ImmutableGetDepositsParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetDepositsResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetDepositsParamsCodec.decode,
      this.getDepositsF,
    );
  }

  public getDepositF({
    id,
  }: ImmutableMethodParams.ImmutableGetDepositParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableDeposit
  > {
    return this.get(
      `deposits/${id}`,
      ImmutableMethodResults.ImmutableDepositCodec.decode,
    );
  }

  public async getDeposit(
    params: ImmutableMethodParams.ImmutableGetDepositParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableDeposit> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetDepositParamsCodec.decode,
      this.getDepositF,
    );
  }

  public getTransferF({
    id,
  }: ImmutableMethodParams.ImmutableGetTransferParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableTransfer
  > {
    return this.get(
      `transfers/${id}`,
      ImmutableMethodResults.ImmutableTransferCodec.decode,
    );
  }

  public async getTransfer(
    params: ImmutableMethodParams.ImmutableGetTransferParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableTransfer> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetTransferParamsCodec.decode,
      this.getTransferF,
    );
  }

  /**
   * Create a signable transfer
   *
   * @experimental - This function is only available for alpha testing
   */
  private getSignableTransferV2F(
    params: ImmutableMethodParams.ImmutableGetSignableTransferV2Params,
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetSignableTransferV2Result
  > {
    return this.post(
      'signable-transfer-details',
      params,
      this.decodeImmutableGetSignableTransferV2Result,
      V2,
    );
  }

  /**
   * Create a signable transfer
   *
   * @experimental - This function is only available for alpha testing
   */
  private async getSignableTransferV2(
    params: ImmutableMethodParams.ImmutableGetSignableTransferV2ParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableGetSignableTransferV2Result> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetSignableTransferV2ParamsCodec.decode,
      this.getSignableTransferV2F,
    );
  }

  private convertToGetSignableTransferV2Params(
    params: ImmutableMethodParams.ImmutableTransferV2Params,
  ): ImmutableMethodParams.ImmutableGetSignableTransferV2Params {
    const signableTransferDetails = params.transfer_request.map(
      ({ token, amount, receiver }) => ({
        amount: token.type === ERC721TokenType.ERC721 ? '1' : amount.toString(),
        receiver: receiver.toLowerCase() as EthAddress,
        token: {
          type: token.type,
          data: this.getTokenBody(token),
        },
      }),
    );
    return {
      sender_ether_key: params.sender_ether_key.toLowerCase() as EthAddress,
      signable_requests: signableTransferDetails,
    };
  }

  /**
   * Create a transfer
   *
   * @experimental - This function is only available for alpha testing
   */
  public transferV2F(
    params: ImmutableMethodParams.ImmutableTransferV2Params,
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableTransferV2Result> {
    return pipe(
      TE.bindTo('getSignableTransferV2Result')(
        this.getSignableTransferV2F(
          this.convertToGetSignableTransferV2Params(params),
        ),
      ),
      TE.bind('ethSignature', ({ getSignableTransferV2Result }) => {
        return this.signEthMessage(
          getSignableTransferV2Result.signable_message,
        );
      }),
      TE.bind(
        'transferRequestWithSignatures',
        ({ getSignableTransferV2Result }) => {
          return this.wallet.controller.transferV2(getSignableTransferV2Result);
        },
      ),
      TE.bind(
        'result',
        ({
          getSignableTransferV2Result,
          transferRequestWithSignatures,
          ethSignature,
        }) =>
          this.post(
            'transfers',
            {
              sender_stark_key: getSignableTransferV2Result.sender_stark_key,
              requests: transferRequestWithSignatures,
            },
            ImmutableMethodResults.ImmutableTransferV2ResultCodec.decode,
            V2,
            addEthHeaders(
              {},
              ethSignature.ethAddress,
              ethSignature.ethSignature,
            ),
          ),
      ),
      TE.map(({ result }) => result),
    );
  }

  /**
   * Create a transfer
   *
   * @experimental - This function is only available for alpha testing
   */
  public async transferV2(
    params: ImmutableMethodParams.ImmutableTransferV2ParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableTransferV2Result> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableTransferV2ParamsCodec.decode,
      this.transferV2F,
    );
  }

  public async getBurn(
    params: ImmutableMethodParams.ImmutableGetBurnParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableBurn> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetBurnParamsCodec.decode,
      this.getTransferF,
    );
  }

  public getWithdrawalF({
    id,
  }: ImmutableMethodParams.ImmutableGetWithdrawalParams): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableWithdrawal
  > {
    return this.get(
      `withdrawals/${id}`,
      ImmutableMethodResults.ImmutableWithdrawalCodec.decode,
    );
  }

  public async getWithdrawal(
    params: ImmutableMethodParams.ImmutableGetWithdrawalParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableWithdrawal> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetWithdrawalParamsCodec.decode,
      this.getWithdrawalF,
    );
  }

  public getWithdrawalsF(
    query: ImmutableMethodParams.ImmutableGetWithdrawalsParams = {},
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetWithdrawalsResult
  > {
    const url = queryString.stringifyUrl({
      url: 'withdrawals',
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetWithdrawalsResultCodec.decode,
    );
  }

  public async getWithdrawals(
    params: ImmutableMethodParams.ImmutableGetWithdrawalsParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetWithdrawalsResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetWithdrawalsParamsCodec.decode,
      this.getWithdrawalsF,
    );
  }

  public getTransfersF(
    query: ImmutableMethodParams.ImmutableGetTransfersParams = {},
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetTransfersResult> {
    const url = queryString.stringifyUrl({
      url: 'transfers',
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetTransfersResultCodec.decode,
    );
  }

  public async getTransfers(
    params: ImmutableMethodParams.ImmutableGetTransfersParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetTransfersResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetTransfersParamsCodec.decode,
      this.getTransfersF,
    );
  }

  public getExchangesF(
    params: ImmutableMethodParams.ImmutableGetExchangesParams = {},
  ): TE.TaskEither<
    Error,
    ImmutableMethodResults.ImmutableGetExchangeHistoryResult
  > {
    const url = queryString.stringifyUrl({
      url: 'exchanges',
      query: params as ImmutableMethodParams.ImmutableGetExchangesParams,
    });

    const decoder =
      ImmutableMethodResults.ImmutableGetExchangeHistoryResultCodec.decode;

    return this.get(url, decoder, V2);
  }

  public getExchanges(
    params: ImmutableMethodParams.ImmutableGetExchangesParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetExchangeHistoryResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetExchangesParamsCodec.decode,
      this.getExchangesF,
    );
  }

  public getBurnsF(
    query: ImmutableMethodParams.ImmutableGetBurnsParams = {},
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetBurnsResult> {
    const burnQuery: ImmutableMethodParams.ImmutableGetBurnsParamsTS = query;
    burnQuery.receiver = BurnAddress.BurnEthAddress;
    const url = queryString.stringifyUrl({
      url: 'transfers',
      query: burnQuery,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetBurnsResultCodec.decode,
    );
  }

  public async getBurns(
    params: ImmutableMethodParams.ImmutableGetBurnsParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetBurnsResult> {
    return decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetBurnsParamsCodec.decode,
      this.getBurnsF,
    );
  }

  public getTradesF(
    query: ImmutableMethodParams.ImmutableGetTradesParams = {},
  ): TE.TaskEither<Error, ImmutableMethodResults.ImmutableGetTradesResult> {
    const url = queryString.stringifyUrl({
      url: 'trades',
      query,
    });

    return this.get(
      url,
      ImmutableMethodResults.ImmutableGetTradesResultCodec.decode,
    );
  }

  public getPaginatedResults<P, T>(
    params: P,
    fn: (params: P) => TE.TaskEither<Error, { cursor: string; result: T[] }>,
  ): TE.TaskEither<Error, T[]> {
    const M = A.getMonoid<T>();
    const loadData = (params: P, result: T[]): TE.TaskEither<Error, T[]> =>
      pipe(
        fn(params),
        TE.chain(data =>
          data.cursor
            ? loadData(
                { ...params, cursor: data.cursor },
                M.concat(result, data.result),
              )
            : TE.of(M.concat(result, data.result)),
        ),
      );

    return loadData(params, []);
  }

  public getTrades(
    params: ImmutableMethodParams.ImmutableGetTradesParamsTS = {},
  ): Promise<ImmutableMethodResults.ImmutableGetTradesResult> {
    return this.decodeForFunction(
      params,
      ImmutableMethodParams.ImmutableGetTradesParamsCodec.decode,
      this.getTradesF,
    );
  }

  public async getLastAvailableNonce(
    starkPublicKey: string,
  ): Promise<{ Nonce: string }> {
    const data = JSON.stringify({
      stark_key: starkPublicKey,
    });

    let nonce = 0;
    try {
      const res = await axios.post(
        'get-last-available-nonce',
        data,
        this.buildOptions(),
      );
      nonce = 'nonce' in res.data.result ? res.data.result.nonce : 0; // @x/core/api/interface.go
    } catch (err) {
      throw formatError(err);
    }
    return { Nonce: String(nonce) };
  }

  public getTokenBody(token: Token): any {
    if (token.type.toUpperCase() === 'ETH') {
      const tokenI = token as ETHToken;
      return {
        decimals: tokenI.data.decimals,
      };
    }
    if (token.type.toUpperCase() === 'ERC20') {
      const tokenI = token as ERC20Token;
      return {
        decimals: tokenI.data.decimals,
        token_address: tokenI.data.tokenAddress,
      };
    }
    if (token.type.toUpperCase() === 'ERC721') {
      const tokenI = token as ERC721Token;
      return {
        token_id: tokenI.data.tokenId,
        token_address: tokenI.data.tokenAddress,
      };
    }
    if (token.type.toUpperCase() === 'MINTABLE_ERC20') {
      const tokenI = token as MintableERC20Token;
      return {
        token_id: getMintingBlobHash(tokenI.data.id, tokenI.data.blueprint),
        token_address: tokenI.data.tokenAddress,
      };
    }
    if (token.type.toUpperCase() === 'MINTABLE_ERC721') {
      const tokenI = token as MintableERC721Token;
      return {
        token_id: getMintingBlobHash(tokenI.data.id, tokenI.data.blueprint),
        token_address: tokenI.data.tokenAddress,
      };
    }
    throw new Error('Invalid token type');
  }

  public parseTokenBody(token: any): any {
    if (token.type.toUpperCase() === 'ETH') {
      return {
        decimals: token.data.decimals,
      };
    }
    if (token.type.toUpperCase() === 'ERC20') {
      return {
        decimals: token.data.decimals,
        tokenAddress: token.data.token_address,
      };
    }
    if (token.type.toUpperCase() === 'ERC721') {
      return {
        tokenId: token.data.token_id,
        tokenAddress: token.data.token_address,
      };
    }
    if (token.type.toUpperCase() === 'MINTABLE_ERC20') {
      return {
        tokenId: getMintingBlobHash(token.data.id, token.data.blueprint),
        tokenAddress: token.data.token_address,
      };
    }
    if (token.type.toUpperCase() === 'MINTABLE_ERC721') {
      return {
        tokenId: getMintingBlobHash(token.data.id, token.data.blueprint),
        tokenAddress: token.data.tokenAddress,
      };
    }
    throw new Error('Invalid token type');
  }

  // This function is needed because the API returns snake cased result for token
  // while to TokenCodec type expects camelcase
  public decodeImmutableGetSignableTransferV2Result(result: any) {
    return E.right({
      signable_responses: result.signable_responses.map(signableResponse => ({
        ...signableResponse,
        token: this.parseTokenBody(signableResponse.token),
      })),
      sender_stark_key: result.sender_stark_key,
      signable_message: result.signable_message,
    });
  }

  private async decodeForFunction<T, U>(
    params: any,
    decode: (x: any) => E.Either<Errors, T>,
    fn: (params: T) => TE.TaskEither<Error, U>,
  ): Promise<U> {
    return valueOrThrow(
      await pipe(
        decode(params),
        E.mapLeft(errorsToError),
        TE.fromEither,
        TE.chain<Error, T, U>(fn),
        TE.mapLeft(formatError),
      )(),
    );
  }

  /**
   * Add signable transfer details
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  private async signableTransferDetails(
    {
      sender,
      token,
      quantity,
      receiver,
    }: ImmutableMethodParams.ImmutableTransferParams,
    exchangeId: number,
  ): Promise<ImmutableMethodResults.ImmutableGetSignableTransferResult> {
    const result = await this.requests.post(
      `exchanges/${exchangeId}/signable-transfer-details`,
      JSON.stringify({
        sender: sender.toLowerCase(),
        token: {
          type: token.type,
          data: this.getTokenBody(token),
        },
        amount:
          token.type === ERC721TokenType.ERC721 ? '1' : quantity.toString(),
        receiver: receiver.toLowerCase(),
      }),
      undefined,
      V2,
    );

    return valueOrThrow(
      ImmutableMethodResults.ImmutableGetSignableTransferResultCodec.decode(
        result,
      ),
    );
  }

  /**
   * Create exchange transfer
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async createExchangeTransfer(
    exchangeId: number,
    {
      sender,
      token,
      quantity,
      receiver,
    }: ImmutableMethodParams.ImmutableTransferParams,
  ): Promise<ImmutableMethodResults.ImmutableTransferResult> {
    const signableTransferResult = await this.signableTransferDetails(
      {
        sender,
        token,
        quantity,
        receiver,
      },
      exchangeId,
    );

    const signature = await this.wallet.controller.transferAsync(
      {
        starkPublicKey: signableTransferResult.sender_stark_key,
        vaultId: signableTransferResult.sender_vault_id,
      },
      {
        starkPublicKey: signableTransferResult.receiver_stark_key,
        vaultId: signableTransferResult.receiver_vault_id,
      },
      token,
      signableTransferResult.asset_id,
      signableTransferResult.amount,
      String(signableTransferResult.nonce),
      String(signableTransferResult.expiration_timestamp),
    );

    const ethSignature = await this.signEthMessageAsync(
      signableTransferResult.signable_message,
    );

    const result = await this.requests.post(
      `exchanges/${exchangeId}/transfers`,
      JSON.stringify({
        sender_stark_key: signableTransferResult.sender_stark_key,
        sender_vault_id: signableTransferResult.sender_vault_id,
        receiver_stark_key: signableTransferResult.receiver_stark_key,
        receiver_vault_id: signableTransferResult.receiver_vault_id,
        amount: signableTransferResult.amount.toString(),
        asset_id: signableTransferResult.asset_id,
        expiration_timestamp: signableTransferResult.expiration_timestamp,
        nonce: signableTransferResult.nonce,
        stark_signature: signature,
      }),
      addEthHeaders({}, ethSignature.ethAddress, ethSignature.ethSignature),
      V2,
    );

    return valueOrThrow(
      ImmutableMethodResults.ImmutableTransferResultCodec.decode(result),
    );
  }

  /**
   * Create an exchange
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async createExchange(
    walletAddress: string,
    provider: ExchangeProvider = 'moonpay',
    type = 'fiatToCrypto',
  ): Promise<ImmutableMethodResults.ImmutableCreateExchangeResult> {
    const result = await this.requests.post(
      'exchanges',
      {
        wallet_address: walletAddress,
        provider,
        type,
      },
      undefined,
      V2,
    );
    return valueOrThrow(ImmutableCreateExchangeResultCodec.decode(result));
  }

  /**
   * Retrieve an exchange
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async getExchange(
    exchange_id: number,
  ): Promise<ImmutableMethodResults.ImmutableGetExchangeResult> {
    const result = await this.requests.get(
      `exchanges/${exchange_id}`,
      undefined,
      undefined,
      V2,
    );
    return valueOrThrow(ImmutableGetExchangeResultCodec.decode(result));
  }

  /**
   * Create a moonpay signature based on the provided request string
   */
  public async getMoonpaySignature(
    req: ImmutableMethodResults.ImmutableGetMoonpaySignatureRequest,
  ): Promise<ImmutableMethodResults.ImmutableGetMoonpaySignatureResult> {
    const validatedRequest = valueOrThrow(
      ImmutableGetMoonpaySignatureRequestCodec.decode(req),
    );
    const reqParam = {
      ...validatedRequest,
      walletAddresses:
        'walletAddresses' in validatedRequest
          ? JSON.stringify(validatedRequest.walletAddresses)
          : undefined,
    };

    return this.handleSignatureRequest(reqParam);
  }

  /**
   * Create a moonpay sell signature based on the provided request string
   */
  public async getMoonpaySellSignature(
    req: ImmutableMethodResults.ImmutableGetMoonpaySellSignatureRequest,
  ): Promise<ImmutableMethodResults.ImmutableGetMoonpaySignatureResult> {
    const validatedRequest = valueOrThrow(
      ImmutableGetMoonpaySellSignatureRequestCodec.decode(req),
    );
    const reqParam = {
      ...validatedRequest,
      refundWalletAddresses:
        'refundWalletAddresses' in validatedRequest
          ? JSON.stringify(validatedRequest.refundWalletAddresses)
          : undefined,
    };

    return this.handleSignatureRequest(reqParam);
  }

  /**
   * Create a primary flow transaction signature based on the provided request string
   */
  public async getMoonpayPrimarySellSignature(
    req: ImmutableMethodResults.ImmutableGetMoonpayPrimarySellSignatureRequest,
  ): Promise<ImmutableMethodResults.ImmutableGetMoonpaySignatureResult> {
    const validatedRequest = valueOrThrow(
      ImmutableGetMoonpayPrimarySellSignatureRequestCodec.decode(req),
    );
    const reqParam = {
      ...validatedRequest,
    };

    return this.handleSignatureRequest(reqParam);
  }

  async handleSignatureRequest(body) {
    const reqParam = {
      ...body,
      showOnlyCurrencies: body.showOnlyCurrencies
        ? body.showOnlyCurrencies.join(',')
        : undefined,
    };

    const result = await this.requests.post(
      'moonpay/sign-url',
      {
        request: queryString.stringify(reqParam),
      },
      undefined,
      V2,
    );
    return valueOrThrow(ImmutableGetMoonpaySignatureResultCodec.decode(result));
  }

  /**
   * Validate currency amount
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async getCurrencies(
    exchangeType: ImmutableExchangeType,
  ): Promise<ImmutableMethodResults.ImmutableGetCurrenciesResult> {
    const result = await this.requests.get(
      `exchanges/currencies/${exchangeType}`,
      undefined,
      undefined,
      V2,
    );
    return valueOrThrow(
      ImmutableMethodResults.ImmutableGetCurrenciesResultCodec.decode(result),
    );
  }

  /**
   * Get currency limits
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async getCurrencyLimits(
    provider: ExchangeProvider = 'moonpay',
  ): Promise<ImmutableMethodResults.ImmutableGetCurrencyLimitsResult> {
    const result = await this.requests.get(
      `${provider}/currency-limits`,
      undefined,
      undefined,
      V2,
    );
    return valueOrThrow(
      ImmutableMethodResults.ImmutableGetCurrencyLimitsCodec.decode(result),
    );
  }

  /**
   * Authentication headers
   */
  private async getAuthenticationHeaders() {
    if (!this.wallet) {
      throw new Error('Please instantiate client with a signer');
    }
    return this.wallet.getAuthenticationHeaders();
  }

  /**
   * Create a project
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async createProject(
    params: CreateProjectParams,
  ): Promise<CreateProjectResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.post('projects', params, authHeaders);
    return valueOrThrow(CreateProjectResultsCodec.decode(result));
  }

  /**
   * Get a list of projects. This list will only return the projects you are an owner of.
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async getProjects(
    params?: ImmutableMethodParams.ImmutablePaginatedParamsTS,
  ): Promise<ProjectsResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.get('projects', params, authHeaders);
    return valueOrThrow(ProjectsResultCodec.decode(result));
  }

  /**
   * Get a project by ID
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async getProject(params: GetProjectParams): Promise<ProjectResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.get(
      `projects/${params.project_id}`,
      null,
      authHeaders,
    );
    return valueOrThrow(ProjectResultCodec.decode(result));
  }

  /**
   * Create a collection
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async createCollection(
    params: CreateCollectionParams,
  ): Promise<CreateCollectionsResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.post(`collections`, params, authHeaders);
    return valueOrThrow(CreateCollectionsResultsCodec.decode(result));
  }

  /**
   * Update a collection
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async updateCollection(
    contractAddress: string,
    params: UpdateCollectionParams,
  ): Promise<UpdateCollectionsResults> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.patch(
      `collections/${contractAddress}`,
      params,
      authHeaders,
    );
    return valueOrThrow(UpdateCollectionsResultCodec.decode(result));
  }

  /**
   * Add metadata schema to collection
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async addMetadataSchemaToCollection(
    contractAddress: string,
    params: AddMetadataSchemaToCollectionParams,
  ): Promise<AddMetadataSchemaToCollectionResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.post(
      `collections/${contractAddress}/metadata-schema`,
      params,
      authHeaders,
    );
    return valueOrThrow(
      AddMetadataSchemaToCollectionResultCodec.decode(result),
    );
  }

  /**
   * Update metadata schema by name
   *
   * @experimental - This function is only available for alpha testing and may change
   */
  public async updateMetadataSchemaByName(
    name: string,
    contractAddress: string,
    params: UpdateMetadataSchemaByNameParams,
  ): Promise<UpdateMetadataSchemaByNameResult> {
    const authHeaders = await this.getAuthenticationHeaders();
    const result = await this.requests.patch(
      `collections/${contractAddress}/metadata-schema/${name}`,
      params,
      authHeaders,
    );
    return valueOrThrow(UpdateMetadataSchemaByNameResultCodec.decode(result));
  }

  /**
   * Get metadata schema
   */
  public async getMetadataSchema(
    params: GetMetadataSchemaParams,
  ): Promise<GetMetadataSchemaResult> {
    const result = await this.requests.get(
      `collections/${params.address}/metadata-schema`,
    );
    return valueOrThrow(GetMetadataSchemaResultCodec.decode(result));
  }

  /**
   * Verify OTP Request
   *
   * @experimental - This function is only available for testing
   */
  public async verifyOTP(params: VerifyOTPParams): Promise<VerifyOTPResponse> {
    const callback = async () => {
      const result = await this.requests.post(`mfa/verification`, params);
      return valueOrThrow(VerifyOTPResponseCodec.decode(result));
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.verifyOTP() is an experimental feature, it should not be used in production.',
    });
  }

  /**
   * Get awaiting order status given a request ID
   *
   * @experimental - This function is only available for testing
   */
  public async getAwaitingOrderStatus(
    requestId: string,
  ): Promise<GetAwaitingOrderStatusResponse> {
    const callback = async () => {
      const result = await this.requests.get(`awaiting-order/${requestId}`);
      return valueOrThrow(GetAwaitingOrderStatusResponseCodec.decode(result));
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.getAwaitingOrderStatus() is an experimental feature, it should not be used in production.',
    });
  }

  /**
   * Create trade request with risk assessment
   *
   * @experimental - This function is only available for testing
   */
  public async createTradeWithRiskAssessment(
    params: ImmutableMethodParams.ImmutableGetSignableTradeParamsTS,
  ): Promise<ImmutableMethodResults.ImmutableCreateTradeResult> {
    const callback = async () => {
      const result = await this.createAssessableTrade(params);
      return valueOrThrow(
        ImmutableMethodResults.ImmutableCreateTradeResultCodec.decode(result),
      );
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.createTradeWithRiskAssessment() is an experimental feature, it should not be used in production.',
    });
  }

  /**
   * Get status of NFT Primary flow transaction
   */
  public async getNFTPrimaryTransactionStatus(
    transactionId: string,
  ): Promise<ImmutableGetNFTPrimaryTransactionStatusResponse> {
    const result = await this.requests.get(
      `/nft/primary/${transactionId}`,
      {},
      {},
      V2,
    );
    return valueOrThrow(
      ImmutableGetNFTPrimaryTransactionStatusResponseCodec.decode(result),
    );
  }

  public getPrimaryTransactionsHistoryF(
    params: ImmutableGetPrimaryTransactionsHistoryParams = {},
  ): TE.TaskEither<Error, ImmutableGetNFTPrimaryTransactionsHistoryResult> {
    const path = queryString.stringifyUrl({
      url: 'nft/primary',
      query: params as ImmutableGetPrimaryTransactionsHistoryParams,
    });

    return this.get(
      path,
      ImmutableGetNFTPrimaryTransactionsHistoryResultCodec.decode,
      V2,
    );
  }

  /**
   * Get NFT Primary flow transactions history list
   */
  public getPrimaryTransactionsHistory(
    params: ImmutableGetPrimaryTransactionsHistoryParamsTS = {},
  ): Promise<ImmutableGetNFTPrimaryTransactionsHistoryResult> {
    return decodeForFunction(
      params,
      ImmutableGetPrimaryTransactionsHistoryParamsCodec.decode,
      this.getPrimaryTransactionsHistoryF,
    );
  }

  /**
   * Create V3 exchange transaction
   *
   * @experimental - This function is only available for testing
   */
  public async createExchangeV3(
    params: ImmutableCreateExchangeV3Params,
  ): Promise<ImmutableCreateExchangeV3Response> {
    const callback = async () => {
      const result = await this.requests.post(
        '/exchanges',
        params,
        undefined,
        V3,
      );
      return valueOrThrow(
        ImmutableCreateExchangeV3ResponseCodec.decode(result),
      );
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.createExchangeV3() is an experimental feature, it should not be used in production.',
    });
  }

  public async nftCheckoutPrimary(
    params: ImmutableNFTCheckoutPrimaryParams,
  ): Promise<ImmutableNFTCheckoutPrimaryResponse> {
    const callback = async () => {
      const result = await this.requests.post(
        '/nft/primary',
        params,
        undefined,
        V2,
      );
      return valueOrThrow(
        ImmutableNFTCheckoutPrimaryResponseCodec.decode(result),
      );
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.nftCheckoutPrimary() is an experimental feature, it should not be used in production.',
    });
  }

  /**
   * Create secondary flow NFT transaction
   *
   * @experimental - This function is only available for testing
   */
  public async nftCheckoutSecondary(
    params: ImmutableNFTCheckoutSecondaryParams,
  ): Promise<ImmutableNFTCheckoutSecondaryResponse> {
    const callback = async () => {
      const result = await this.requests.post(
        '/nft/secondary',
        params,
        undefined,
        V2,
      );
      return valueOrThrow(
        ImmutableNFTCheckoutSecondaryResponseCodec.decode(result),
      );
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.nftCheckoutSecondary() is an experimental feature, it should not be used in production.',
    });
  }

  /**
   * Get status of NFT Secondary flow transaction
   *
   * @experimental - This function is only available for testing
   */
  public async getNFTSecondaryTransactionStatus(
    transactionId: string,
  ): Promise<ImmutableGetNFTSecondaryTransactionStatusResponse> {
    const callback = async () => {
      const result = await this.requests.get(
        `/nft/secondary/${transactionId}`,
        {},
        {},
        V2,
      );
      return valueOrThrow(
        ImmutableGetNFTSecondaryTransactionStatusResponseCodec.decode(result),
      );
    };
    return experimental(callback, {
      runCondition: true,
      checkReferrer: true,
      message:
        'client.getNFTSecondaryTransactionStatus() is an experimental feature, it should not be used in production.',
    });
  }
}

export default ImmutableXClient;
