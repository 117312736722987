import and from './and';
import create from './create';
import modify from './modify';
import nand from './nand';
import nor from './nor';
import not from './not';
import or from './or';
import read from './read';
import readInt from './read-int';
import readUInt from './read-u-int';
import xnor from './xnor';
import xor from './xor';
export { and, create, modify, nand, nor, not, or, read, readInt, readUInt, xnor, xor, };
export default {
    and: and,
    create: create,
    modify: modify,
    nand: nand,
    nor: nor,
    not: not,
    or: or,
    read: read,
    readInt: readInt,
    readUInt: readUInt,
    xnor: xnor,
    xor: xor,
};
