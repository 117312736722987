class Wallet {
  constructor(wallet = {}, authorized = false) {
    this.wallet = wallet;
    this.connected = authorized;
    this.assets = [];
  }

  getWallet() {
    return this.wallet;
  }

  isConntected() {
    return this.connected;
  }

  disconnectWallet() {
    this.wallet = null;
    this.connected = false;
    this.assets = [];
  }

  initializeWallet(walletInfo) {
    this.wallet = walletInfo;
    this.connected = true;
  }

  setAssets(assets = []) {
    this.assets = assets;
  }

  getAssets() {
    return this.assets;
  }
}

export const wallet = new Wallet();
