import { p2 } from '../utilities';
import read from './read';
/**
 * Converts a section of a buffer to an unsigned integer.
 *
 * @example
 * // buffer 11110110
 * readUInt(buffer, 3, 5) => 22
 *
 * @param {Buffer} buffer the buffer to extract information from
 * @param {Number} length the length of the unsigned integer (in bits)
 * @param {Number} offset where to start (in bits)
 * @returns {Number}
 */
export default (function (buffer, offset, length) {
    if (offset === void 0) { offset = 0; }
    if (length === void 0) { length = 8; }
    var arr = read(buffer, offset, length);
    var result = 0;
    for (var i = 0; i < length; i++)
        result += arr[i] * p2[length - i - 1];
    return result;
});
