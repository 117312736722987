import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import { ExternalProvider } from '@ethersproject/providers/lib/web3-provider';
import detectGamestopProvider from '@gamestopnft/detect-gamestop-provider';
import { IWalletConnectProviderOptions } from '@walletconnect/types';
import * as E from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { EthNetworkConfiguration } from 'magic-sdk';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

import { BalanceInfo, ProviderPreference } from '../types';
import { erc20 } from './abi';
import { LocalStorageKeys } from './constants';
import { createExtensionProvider } from './extensionProvider';
import { taskEitherWithError } from './fp';
import { getJsonRPCProvider } from './jsonRpcProvider';
import { createMagicProvider } from './magicProvider';
import { ethToken } from './utils';
import { createWalletConnectProvider } from './walletConnectProvider';

interface GetReadOnlyProviderInput {
  rpcUrl: string;
  interval?: number;
}
interface GetERC20BalanceV2Input extends GetReadOnlyProviderInput {
  owner: string;
  tokenAddress: string;
}

interface GetBalanceV2Input extends GetReadOnlyProviderInput {
  address: string;
}

export interface MagicProviderOptions {
  network: EthNetworkConfiguration;
  publishableKey: string;
}

export class UnexpectedProviderError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnexpectedProviderError';
  }
}

const getEthereumProvider = ({
  providerPreference,
  options = {},
}: {
  providerPreference?: ProviderPreference;
  options?: GetProviderOptionInput;
}): Promise<ExternalProvider | null> => {
  const { walletConnectProviderOption, magicProviderOptions } = options;
  const { ethereum, gamestop } = window as any;
  const shouldRunGamestop =
    (ethereum?.isGamestop || gamestop) && !providerPreference;
  const shouldRunMetamask =
    ethereum?.isMetaMask === true && !providerPreference;

  if (providerPreference === ProviderPreference.METAMASK || shouldRunMetamask) {
    return createExtensionProvider({ mustBeMetaMask: true });
  }
  if (providerPreference === ProviderPreference.GAMESTOP || shouldRunGamestop) {
    return detectGamestopProvider();
  }

  if (
    providerPreference === ProviderPreference.WALLET_CONNECT &&
    walletConnectProviderOption !== undefined
  ) {
    return createWalletConnectProvider(walletConnectProviderOption);
  }

  if (
    providerPreference === ProviderPreference.MAGIC_LINK &&
    magicProviderOptions
  ) {
    return createMagicProvider(magicProviderOptions);
  }

  return Promise.reject(new Error('Unknown Ethereum provider'));
};

type GetProviderOptionInput = {
  walletConnectProviderOption?: IWalletConnectProviderOptions;
  magicProviderOptions?: MagicProviderOptions;
};

export const getProvider = (
  options: GetProviderOptionInput = {},
): TE.TaskEither<Error, Web3Provider> =>
  pipe(
    taskEitherWithError(() => {
      const providerPreference =
        typeof window !== 'undefined'
          ? (window.localStorage?.getItem(
              LocalStorageKeys.PROVIDER_PREFERENCE,
            ) as ProviderPreference)
          : undefined;
      return getEthereumProvider({
        providerPreference,
        options,
      });
    }),
    TE.chain(
      flow(
        E.fromNullable(
          new Error('Ethereum provider not found, please install MetaMask'),
        ),
        TE.fromEither,
      ),
    ),
    TE.map(provider => new Web3Provider(provider as ExternalProvider)),
  );

export const ethBalance = (
  address: string,
  interval = 5000,
  providerOptions: GetProviderOptionInput = {},
) => {
  const balanceObs = new Observable<BalanceInfo>(subscriber => {
    const updateBalance = (provider: Web3Provider): T.Task<void> =>
      subscriber.closed
        ? T.of(undefined)
        : pipe(
            taskEitherWithError(() => provider.getBalance(address)),
            TE.chain(balance =>
              TE.fromIO(() =>
                subscriber.next({ balance, decimal: ethToken.data.decimals }),
              ),
            ),
            TE.fold(
              e => T.fromIO(() => subscriber.error(e)),
              () => pipe(updateBalance(provider), T.delay(interval)),
            ),
          );
    const run = pipe(
      providerOptions,
      getProvider,
      TE.fold(e => T.fromIO(() => subscriber.error(e)), updateBalance),
    );
    run();
  });

  return pipe(
    balanceObs,
    distinctUntilChanged((prev, curr) => prev.balance.eq(curr.balance)),
  );
};

export const erc20Balance = (
  owner: string,
  tokenAddress: string,
  interval = 5000,
  providerOptions: GetProviderOptionInput = {},
) => {
  const balanceObs = new Observable<BalanceInfo>(subscriber => {
    const updateBalance = (provider: Web3Provider): T.Task<void> =>
      subscriber.closed
        ? T.of(undefined)
        : pipe(
            taskEitherWithError(async () => {
              const erc20Contract = new Contract(tokenAddress, erc20, provider);
              const [balance, decimal] = await Promise.all([
                erc20Contract.balanceOf(owner),
                erc20Contract.decimals(),
              ]);
              return { balance: BigNumber.from(balance), decimal };
            }),
            TE.chain(balanceObj =>
              TE.fromIO(() => subscriber.next(balanceObj)),
            ),
            TE.fold(
              e => T.fromIO(() => subscriber.error(e)),
              () => pipe(updateBalance(provider), T.delay(interval)),
            ),
          );
    const run = pipe(
      providerOptions,
      getProvider,
      TE.fold(e => T.fromIO(() => subscriber.error(e)), updateBalance),
    );
    run();
  });

  return pipe(
    balanceObs,
    distinctUntilChanged((prev, curr) => prev.balance.eq(curr.balance)),
  );
};

const getGamestopWeb3Provider = async () => {
  const gamestopProvider = await detectGamestopProvider();
  return new Web3Provider(gamestopProvider as ExternalProvider);
};

const isGamestopProvider = (): boolean => {
  const providerPreference =
    typeof window !== 'undefined'
      ? (window.localStorage?.getItem(
          LocalStorageKeys.PROVIDER_PREFERENCE,
        ) as ProviderPreference)
      : undefined;
  return providerPreference === ProviderPreference.GAMESTOP;
};

const getMetamaskWeb3Provider = async () => {
  const metamaskProvider = await createExtensionProvider({
    mustBeMetaMask: true,
  });
  return new Web3Provider(metamaskProvider as ExternalProvider);
};

const isMetamaskProvider = (): boolean => {
  const providerPreference =
    typeof window !== 'undefined'
      ? (window.localStorage?.getItem(
          LocalStorageKeys.PROVIDER_PREFERENCE,
        ) as ProviderPreference)
      : undefined;
  return providerPreference === ProviderPreference.METAMASK;
};

const getReadOnlyProvider = async (
  rpcUrl: string,
): Promise<JsonRpcProvider | Web3Provider> => {
  if (isMetamaskProvider()) {
    return getMetamaskWeb3Provider();
  }

  if (isGamestopProvider()) {
    return getGamestopWeb3Provider();
  }

  return getJsonRPCProvider(rpcUrl);
};

const getEthBalanceWithDecimal = async (
  provider: Web3Provider | JsonRpcProvider,
  address: string,
): Promise<BalanceInfo> => {
  const balance = await provider.getBalance(address);
  return { balance, decimal: ethToken.data.decimals };
};

const getErc20BalanceWithDecimal = async (
  erc20Contract: Contract,
  owner: string,
): Promise<BalanceInfo> => {
  const [balance, decimal] = await Promise.all([
    erc20Contract.balanceOf(owner),
    erc20Contract.decimals(),
  ]);
  return {
    balance: BigNumber.from(balance),
    decimal,
  };
};

export const ethBalanceV2 = ({ address, rpcUrl }: GetBalanceV2Input) =>
  from(getReadOnlyProvider(rpcUrl)).pipe(
    mergeMap(provider => getEthBalanceWithDecimal(provider, address)),
  );

export const erc20BalanceV2 = ({
  owner,
  tokenAddress,
  rpcUrl,
}: GetERC20BalanceV2Input) =>
  from(getReadOnlyProvider(rpcUrl)).pipe(
    map(provider => new Contract(tokenAddress, erc20, provider)),
    mergeMap(erc20Contract => getErc20BalanceWithDecimal(erc20Contract, owner)),
  );
