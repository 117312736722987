import { Link } from "@imtbl/imx-sdk";
import { wallet } from "./classes/Wallet";

import { LINK_URL, USER_KEY } from "./constants";
import { WALLET_ICON } from "./Icons";
import { getUserAssets } from "./utils/imxAPI";
import { LocalStorage } from "./utils/localstorage";
import { log } from "./utils/logger";

export async function setupLogin() {
  let link = new Link(LINK_URL);

  try {
    const response = await link.setup({});
    LocalStorage.set(USER_KEY, response);
    handleInitializeWalletUI(response);
    wallet.initializeWallet(response);
    const assets = await getUserAssets({ user: response.address });
    wallet.setAssets(assets);
  } catch (error) {
    log(`Wallet connection failled... \n\n${error.message}`, true);
  }
}

export function handleDisconnectWalletUI() {
  const loginButton = document.getElementById("connectWallet");
  loginButton.className = "button button--contained";
  loginButton.innerText = "Connect IMX Wallet";
  const disconnectButton = document.getElementById("disconnectWallet");
  disconnectButton.style.display = "none";
  loginButton.removeEventListener("click", () => {
    const disconnectButton = document.getElementById("disconnectWallet");
    disconnectButton.style.display = "initial";
  });
}

export function handleInitializeWalletUI(user = { address: "" }) {
  const initializedUser = wallet.initializeWallet(user);
  const loginButton = document.getElementById("connectWallet");
  loginButton.className = "button button--outlined button-connected";
  loginButton.innerHTML = `${WALLET_ICON} 
  <span>${user?.address.substr(0, 6)}...${user?.address.substr(
    user?.address?.length - 4,
    4
  )}</span>`;
  loginButton.blur();
  return initializedUser;
}
