export const LINK_MESSAGE_TYPE = 'imx-link';
export const messageTypes = {
  inProgress: 'inProgress',
  success: 'success',
  fail: 'fail',
  result: 'result',
  close: 'close',
  ready: 'ready',
  transfer: 'transfer',
  batchNftTransfer: 'batchNftTransfer',
  sign: 'sign',
  getPublicKey: 'getPublicKey',
};

export type Message = {
  type: string;
  message: string;
  payload?: any;
  version?: number;
};
