import "./style.css";
import {
  handleDisconnectWalletUI,
  handleInitializeWalletUI,
  setupLogin,
} from "./scripts/connectWallet.js";
import { uploadFile } from "./scripts/uploadFile";
import {
  ButtonConfirm,
  CircularLoading,
  FileCardElements,
  UploadFileElements,
  USER_KEY,
} from "./scripts/constants";
import { LocalStorage } from "./scripts/utils/localstorage";
import { wallet } from "./scripts/classes/Wallet";
import { getUserAssets } from "./scripts/utils/imxAPI";
import { log } from "./scripts/utils/logger";

function handleShowDisconnect() {
  const disconnectButton = document.getElementById("disconnectWallet");
  disconnectButton.style.display = "initial";

  disconnectButton.addEventListener("click", () => {
    LocalStorage.remove(USER_KEY);
    wallet.disconnectWallet();
    handleDisconnectWalletUI();
    showUploadFileCard();
    log("Waiting for file_", true);
  });
}

export function handleUploadFileFunctions() {
  const dropArea = document.querySelector(".upload-container");
  dropArea.innerHTML = UploadFileElements;
  const uploadButton = dropArea.querySelector(".upload-button");
  const input = dropArea.querySelector("input");

  uploadButton.onclick = async () => {
    if (!wallet.isConntected()) {
      await setupLogin();
    } else input.click();
  };

  input.addEventListener("change", function (e) {
    const selectedFile = e.target.files[0];
    uploadFile(selectedFile);
  });
}

export function handleCloseFileFunctions() {
  const closeIcon = document.getElementById("removeFile");

  closeIcon.addEventListener("click", function () {
    showUploadFileCard();
    log("Waiting for file_", true);
  });
}

export function startLoadingButton() {
  const transferButton = document.getElementById("transferButton");
  transferButton.innerHTML = CircularLoading;
  transferButton.style.opacity = 0.7;
  transferButton.disabled = true;
}
export function endLoadingButton(hasError = false) {
  const transferButton = document.getElementById("transferButton");
  transferButton.innerHTML = ButtonConfirm;
  transferButton.style.opacity = hasError ? 0.7 : 1;
  transferButton.disabled = hasError ? true : false;
}

export async function handleInitializeWallet() {
  // initializing user
  const walletInfo = LocalStorage.get(USER_KEY);
  if (typeof walletInfo === "object" && walletInfo !== null) {
    handleInitializeWalletUI(walletInfo);

    const userAssets = await getUserAssets({
      user: wallet.wallet.address,
    });

    wallet.setAssets(userAssets);
  }

  // Handles click on connecting wallet
  const loginButton = document.getElementById("connectWallet");
  loginButton.addEventListener("click", () => {
    if (wallet.isConntected()) {
      handleShowDisconnect();
    } else setupLogin();
  });
}

handleInitializeWallet()
  .then(function () {
    console.log("wallet initialized");
  })
  .catch(function (e) {
    console.log(e);
  });

handleUploadFileFunctions();

export function showUploadFileCard() {
  const uploadContainer = document.getElementById("uploadContainer");
  uploadContainer.classList = "upload-container";
  uploadContainer.innerHTML = UploadFileElements;
  handleUploadFileFunctions();
}

export function showFileCard() {
  const uploadContainer = document.getElementById("uploadContainer");
  uploadContainer.classList = "";
  uploadContainer.innerHTML = FileCardElements;
  handleCloseFileFunctions();
}
