import { FILE_ICON } from "./Icons";
import { formatBytes } from "./utils/file";

export const EXTERNAL_URL = {
  SDK: {
    sandbox: "https://link.sandbox.x.immutable.com",
    mainnet: "https://link.x.immutable.com",
  },
  API: {
    sandbox: "https://api.sandbox.x.immutable.com/v1",
    mainnet: "https://api.x.immutable.com/v1",
  },
  delysium: "https://delysium.com",
  imx: "https://immutable.com",
};

const { SDK, API } = EXTERNAL_URL;

const isDev =
  window.location.origin.includes("local") ||
  window.location.origin.includes("vercel");

export const LINK_URL = isDev ? SDK.sandbox : SDK.mainnet;
export const API_URL = isDev ? API.sandbox : API.mainnet;
export const COLLECTION_ADDRESS = isDev
  ? "0x1c4188106941d706ffc323f64318ed4fd308fad5"
  : "0x4f7e0538827bbf082fce398c3877ba950d1c0896";

export const PALLETE = {
  black: "#000",
  primary: "#FAE232",
  primaryDark: "rgba(250, 226, 50, 0.8)",
  primaryDarker: "rgba(250, 226, 50, 0.5)",
};

export const USER_KEY = "DEL_IMX_INFO";

export const UploadFileElements = `<input type="file" hidden accept=".excel,.csv" id="fileID" style="display:none;">
<button id="uploadButton" class="button button--contained upload-button">
  <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.7269 10.5458H19.3636V25.2728H2.6363V2.72736H11.727V8.72763H11.7269V10.5458ZM13.5452 3.11661L19.1562 8.72763H13.5452V3.11661ZM13.909 0.90918L21.1818 8.18191V25.2728V27.091H19.3636H2.6363H0.818115V25.2728V2.72736V0.90918H2.6363H13.909ZM11.7273 12.5459V16.1823H15.3637V17.6368H11.7273V21.2732H10.2727L10.2727 17.6368H6.63647V16.1823H10.2727V12.5459H11.7273Z"
      fill="black" />
  </svg>
  <span role="button">
    Choose File
  </span>
</button>
<p class="download-prompt">
  Download
  <a href="https://storage.delysium.com/assets/Batch%20Transfer%20Template.csv" target="_blank" rel="noopener noreferrer">CSV Template</a>
</p>`;

export function printFileInfo(name = "", size = "") {
  const nameContainer = document.getElementById("fileName");

  nameContainer.innerText = name;
}

export const FileCardElements = `<div>
<div class="upload-container upload-container--loaded"> 
  ${FILE_ICON}
  <div style="flex-grow: 1; display: flex; flex-flow: column nowrap; margin-left: 24px;">
    <p style="font-size: 16px;" id="fileName"></p>
    <div style="font-size: 14px; color: rgba(255, 255, 255, 0.7);" id="fileSize">
      <div id="progress" class="progress">
        <div id="progressValue" class="progress-value"></div>
      </div>
      <span id="loadProgress" style="float: right;"></span>
    </div>
  </div>
  <svg id="removeFile" style="align-self: flex-start; cursor: pointer;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1.18519L1 11M1 1L11 10.8148" stroke="white"/>
  </svg>      
</div>
<button class="button button--contained transfer-button" id="transferButton">
 </button>
</div>`;

export const CircularLoading = `<div class="lds-ring"><div></div><div></div><div></div><div></div></div>`;
export const ButtonConfirm = `Confirm 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_30921_106366" fill="white">
    <path d="M12.0713 5L19.1424 12.0711L12.0713 19.1421L5.00022 12.0711L12.0713 5Z"/>
  </mask>
  <path d="M19.1424 12.0711L20.5566 13.4853L21.9708 12.0711L20.5566 10.6569L19.1424 12.0711ZM10.6571 6.41421L17.7281 13.4853L20.5566 10.6569L13.4855 3.58579L10.6571 6.41421ZM17.7281 10.6569L10.6571 17.7279L13.4855 20.5563L20.5566 13.4853L17.7281 10.6569Z" fill="black" mask="url(#path-1-inside-1_30921_106366)"/>
  <rect x="17.1426" y="13.1421" width="12" height="2" transform="rotate(-180 17.1426 13.1421)" fill="black"/>
</svg>`;
