import { Link } from "@imtbl/imx-sdk";
import {
  endLoadingButton,
  showUploadFileCard,
  startLoadingButton,
} from "../../main";
import { API_URL, COLLECTION_ADDRESS, LINK_URL } from "../constants";
import { log } from "./logger";

export async function batchNFTTransfer(items = []) {
  let link = new Link(LINK_URL);
  startLoadingButton();

  try {
    let { result } = await link.batchNftTransfer(items);
    if (result?.length > 0) {
      log(" ", true);
      result.map((item) => {
        log(`Transferred ${item.tokenId} to ${item.toAddress}\n`);
      });
    }
    endLoadingButton(false);
    showUploadFileCard();
    log(`Transfer Completed........`);
  } catch (error) {
    showUploadFileCard();
    console.log(error);
    log(`Transfer Failed.......`, true);
  }
}

//@TODO: Add collection address and metadata as parameters
export async function getUserAssets({ user, collection = COLLECTION_ADDRESS }) {
  try {
    var currentCursor = "";
    var hasMoreItems = true;
    var assets = [];

    log(
      `PLEASE WAIT...\n\n\nFetching user assets for collection ${collection}\n\nTotal assets fetched: ${assets.length} `,
      true
    );

    while (hasMoreItems) {
      const uploadButton = document.getElementById("uploadButton");
      if (uploadButton !== null) {
        uploadButton.disabled = true;
      }
      const fetched = await fetch(
        `${API_URL}/assets?user=${user}&collection=${collection}&page_size=500&cursor=${currentCursor}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await fetched.json();
      assets = assets.concat(response.result);
      hasMoreItems = response.remaining === 1;
      currentCursor = response.cursor;

      log(
        `PLEASE WAIT...\n\n\nFetching user assets for collection ${collection}\n\nTotal assets fetched: ${assets.length} `,
        true
      );
    }

    log(`Fetched assets successfuly.\n\n\n Waiting for file__ `);

    //Grouping assets by modelID
    const groupedAssets = assets.reduce(function (prev, current) {
      prev[current.metadata.model_id] = prev[current.metadata.model_id] || [];
      prev[current.metadata.model_id].push(current);

      return prev;
    }, Object.create(null));

    const uploadButton = document.getElementById("uploadButton");
    if (uploadButton !== null) {
      uploadButton.disabled = false;
    }

    return groupedAssets;
  } catch (error) {
    log(`Failed to fetch user assets...\n\n PLEASE REFRESH THE PAGE.`, true);
  }
}
