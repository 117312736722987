import { p2 } from '../utilities';
import not from '../bits/not';
import read from './read';
/**
 * Converts a section of a buffer to a signed integer.
 *
 * @example
 * // buffer 11110110
 * readUInt(buffer, 3, 5) => -10
 *
 * @param {Buffer} buffer the buffer to extract information from
 * @param {Number} length the length of the signed integer (in bits)
 * @param {Number} offset where to start (in bits)
 * @return {Number}
 */
export default (function (buffer, offset, length) {
    if (offset === void 0) { offset = 0; }
    if (length === void 0) { length = 8; }
    var bits = read(buffer, offset, length);
    if (bits[0] === 0) {
        var result = 0;
        for (var i = 0; i < length; i++)
            if (bits[i])
                result += p2[length - i - 1];
        return result;
    }
    else {
        var result = -1;
        var inverted = not(bits);
        for (var i = 0; i < length; i++)
            if (inverted[i])
                result -= p2[length - i - 1];
        return result;
    }
});
