"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctorWithIndex = exports.Functor = exports.URI = exports.separate = exports.compact = exports.reduceRight = exports.reduce = exports.partitionMap = exports.partition = exports.foldMap = exports.filterMap = exports.filter = exports.elem = exports.some = exports.every = exports.fromFoldableMap = exports.fromFoldable = exports.filterWithIndex = exports.filterMapWithIndex = exports.partitionWithIndex = exports.partitionMapWithIndex = exports.wilt = exports.wither = exports.sequence = exports.traverse = exports.traverseWithIndex = exports.singleton = exports.reduceRightWithIndex = exports.foldMapWithIndex = exports.reduceWithIndex = exports.map = exports.mapWithIndex = exports.empty = exports.lookup = exports.getMonoid = exports.getEq = exports.isSubrecord = exports.pop = exports.modifyAt = exports.updateAt = exports.deleteAt = exports.hasOwnProperty = exports.insertAt = exports.toUnfoldable = exports.toArray = exports.collect = exports.keys = exports.isEmpty = exports.size = exports.getShow = void 0;
exports.record = exports.Witherable = exports.TraversableWithIndex = exports.Traversable = exports.FilterableWithIndex = exports.Filterable = exports.Compactable = exports.FoldableWithIndex = exports.Foldable = void 0;
var RR = __importStar(require("./ReadonlyRecord"));
/* tslint:disable:readonly-array */
// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------
/**
 * @since 2.0.0
 */
exports.getShow = RR.getShow;
/**
 * Calculate the number of key/value pairs in a record
 *
 * @since 2.0.0
 */
exports.size = RR.size;
/**
 * Test whether a record is empty
 *
 * @since 2.0.0
 */
exports.isEmpty = RR.isEmpty;
/**
 * @since 2.0.0
 */
exports.keys = RR.keys;
/**
 * Map a record into an array
 *
 * @example
 * import {collect} from 'fp-ts/Record'
 *
 * const x: { a: string, b: boolean } = { a: 'foo', b: false }
 * assert.deepStrictEqual(
 *   collect((key, val) => ({key: key, value: val}))(x),
 *   [{key: 'a', value: 'foo'}, {key: 'b', value: false}]
 * )
 *
 * @since 2.0.0
 */
exports.collect = RR.collect;
/**
 * @since 2.0.0
 */
exports.toArray = RR.toReadonlyArray;
function toUnfoldable(U) {
    return RR.toUnfoldable(U);
}
exports.toUnfoldable = toUnfoldable;
function insertAt(k, a) {
    return RR.insertAt(k, a);
}
exports.insertAt = insertAt;
/**
 * @since 2.0.0
 */
exports.hasOwnProperty = RR.hasOwnProperty;
function deleteAt(k) {
    return RR.deleteAt(k);
}
exports.deleteAt = deleteAt;
/**
 * @since 2.0.0
 */
exports.updateAt = RR.updateAt;
/**
 * @since 2.0.0
 */
exports.modifyAt = RR.modifyAt;
function pop(k) {
    return RR.pop(k);
}
exports.pop = pop;
// TODO: remove non-curried overloading in v3
/**
 * Test whether one record contains all of the keys and values contained in another record
 *
 * @since 2.0.0
 */
exports.isSubrecord = RR.isSubrecord;
function getEq(E) {
    return RR.getEq(E);
}
exports.getEq = getEq;
function getMonoid(S) {
    return RR.getMonoid(S);
}
exports.getMonoid = getMonoid;
// TODO: remove non-curried overloading in v3
/**
 * Lookup the value for a key in a record
 *
 * @since 2.0.0
 */
exports.lookup = RR.lookup;
/**
 * @since 2.0.0
 */
exports.empty = {};
function mapWithIndex(f) {
    return RR.mapWithIndex(f);
}
exports.mapWithIndex = mapWithIndex;
function map(f) {
    return RR.map(f);
}
exports.map = map;
function reduceWithIndex(b, f) {
    return RR.reduceWithIndex(b, f);
}
exports.reduceWithIndex = reduceWithIndex;
function foldMapWithIndex(M) {
    return RR.foldMapWithIndex(M);
}
exports.foldMapWithIndex = foldMapWithIndex;
function reduceRightWithIndex(b, f) {
    return RR.reduceRightWithIndex(b, f);
}
exports.reduceRightWithIndex = reduceRightWithIndex;
/**
 * Create a record with one key/value pair
 *
 * @since 2.0.0
 */
exports.singleton = RR.singleton;
function traverseWithIndex(F) {
    return RR.traverseWithIndex(F);
}
exports.traverseWithIndex = traverseWithIndex;
function traverse(F) {
    return RR.traverse(F);
}
exports.traverse = traverse;
function sequence(F) {
    return RR.sequence(F);
}
exports.sequence = sequence;
/**
 * @category Witherable
 * @since 2.6.5
 */
exports.wither = RR.wither;
/**
 * @category Witherable
 * @since 2.6.5
 */
exports.wilt = RR.wilt;
function partitionMapWithIndex(f) {
    return RR.partitionMapWithIndex(f);
}
exports.partitionMapWithIndex = partitionMapWithIndex;
function partitionWithIndex(predicateWithIndex) {
    return RR.partitionWithIndex(predicateWithIndex);
}
exports.partitionWithIndex = partitionWithIndex;
function filterMapWithIndex(f) {
    return RR.filterMapWithIndex(f);
}
exports.filterMapWithIndex = filterMapWithIndex;
function filterWithIndex(predicateWithIndex) {
    return RR.filterWithIndex(predicateWithIndex);
}
exports.filterWithIndex = filterWithIndex;
function fromFoldable(M, F) {
    return RR.fromFoldable(M, F);
}
exports.fromFoldable = fromFoldable;
function fromFoldableMap(M, F) {
    return RR.fromFoldableMap(M, F);
}
exports.fromFoldableMap = fromFoldableMap;
/**
 * @since 2.0.0
 */
exports.every = RR.every;
/**
 * @since 2.0.0
 */
exports.some = RR.some;
// TODO: remove non-curried overloading in v3
/**
 * @since 2.0.0
 */
exports.elem = RR.elem;
// -------------------------------------------------------------------------------------
// non-pipeables
// -------------------------------------------------------------------------------------
var map_ = RR.Functor.map;
var mapWithIndex_ = RR.FunctorWithIndex.mapWithIndex;
var reduce_ = RR.Foldable.reduce;
var foldMap_ = RR.Foldable.foldMap;
var reduceRight_ = RR.Foldable.reduceRight;
var reduceWithIndex_ = RR.FoldableWithIndex.reduceWithIndex;
var foldMapWithIndex_ = RR.FoldableWithIndex.foldMapWithIndex;
var reduceRightWithIndex_ = RR.FoldableWithIndex.reduceRightWithIndex;
var filter_ = RR.Filterable.filter;
var filterMap_ = RR.Filterable.filterMap;
var partition_ = RR.Filterable.partition;
var partitionMap_ = RR.Filterable.partitionMap;
var filterWithIndex_ = RR.FilterableWithIndex
    .filterWithIndex;
var filterMapWithIndex_ = RR.FilterableWithIndex.filterMapWithIndex;
var partitionWithIndex_ = RR.FilterableWithIndex
    .partitionWithIndex;
var partitionMapWithIndex_ = RR.FilterableWithIndex.partitionMapWithIndex;
var traverseWithIndex_ = RR.TraversableWithIndex
    .traverseWithIndex;
var wither_ = RR.Witherable.wither;
var wilt_ = RR.Witherable.wilt;
var traverse_ = function (F) {
    var traverseF = traverse(F);
    return function (ta, f) { return traverseF(f)(ta); };
};
// -------------------------------------------------------------------------------------
// pipeables
// -------------------------------------------------------------------------------------
/**
 * @category Filterable
 * @since 2.0.0
 */
exports.filter = RR.filter;
/**
 * @category Filterable
 * @since 2.0.0
 */
exports.filterMap = RR.filterMap;
/**
 * @category Foldable
 * @since 2.0.0
 */
exports.foldMap = RR.foldMap;
/**
 * @category Filterable
 * @since 2.0.0
 */
exports.partition = RR.partition;
/**
 * @category Filterable
 * @since 2.0.0
 */
exports.partitionMap = RR.partitionMap;
/**
 * @category Foldable
 * @since 2.0.0
 */
exports.reduce = RR.reduce;
/**
 * @category Foldable
 * @since 2.0.0
 */
exports.reduceRight = RR.reduceRight;
/**
 * @category Compactable
 * @since 2.0.0
 */
exports.compact = RR.compact;
/**
 * @category Compactable
 * @since 2.0.0
 */
exports.separate = RR.separate;
// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------
/**
 * @category instances
 * @since 2.0.0
 */
exports.URI = 'Record';
/**
 * @category instances
 * @since 2.7.0
 */
exports.Functor = {
    URI: exports.URI,
    map: map_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.FunctorWithIndex = {
    URI: exports.URI,
    map: map_,
    mapWithIndex: mapWithIndex_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.Foldable = {
    URI: exports.URI,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.FoldableWithIndex = {
    URI: exports.URI,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_,
    reduceWithIndex: reduceWithIndex_,
    foldMapWithIndex: foldMapWithIndex_,
    reduceRightWithIndex: reduceRightWithIndex_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.Compactable = {
    URI: exports.URI,
    compact: exports.compact,
    separate: exports.separate
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.Filterable = {
    URI: exports.URI,
    map: map_,
    compact: exports.compact,
    separate: exports.separate,
    filter: filter_,
    filterMap: filterMap_,
    partition: partition_,
    partitionMap: partitionMap_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.FilterableWithIndex = {
    URI: exports.URI,
    map: map_,
    mapWithIndex: mapWithIndex_,
    compact: exports.compact,
    separate: exports.separate,
    filter: filter_,
    filterMap: filterMap_,
    partition: partition_,
    partitionMap: partitionMap_,
    filterMapWithIndex: filterMapWithIndex_,
    filterWithIndex: filterWithIndex_,
    partitionMapWithIndex: partitionMapWithIndex_,
    partitionWithIndex: partitionWithIndex_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.Traversable = {
    URI: exports.URI,
    map: map_,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_,
    traverse: traverse_,
    sequence: sequence
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.TraversableWithIndex = {
    URI: exports.URI,
    map: map_,
    mapWithIndex: mapWithIndex_,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_,
    reduceWithIndex: reduceWithIndex_,
    foldMapWithIndex: foldMapWithIndex_,
    reduceRightWithIndex: reduceRightWithIndex_,
    traverse: traverse_,
    sequence: sequence,
    traverseWithIndex: traverseWithIndex_
};
/**
 * @category instances
 * @since 2.7.0
 */
exports.Witherable = {
    URI: exports.URI,
    map: map_,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_,
    traverse: traverse_,
    sequence: sequence,
    compact: exports.compact,
    separate: exports.separate,
    filter: filter_,
    filterMap: filterMap_,
    partition: partition_,
    partitionMap: partitionMap_,
    wither: wither_,
    wilt: wilt_
};
// TODO: remove in v3
/**
 * @category instances
 * @since 2.0.0
 */
exports.record = {
    URI: exports.URI,
    map: map_,
    reduce: reduce_,
    foldMap: foldMap_,
    reduceRight: reduceRight_,
    traverse: traverse_,
    sequence: sequence,
    compact: exports.compact,
    separate: exports.separate,
    filter: filter_,
    filterMap: filterMap_,
    partition: partition_,
    partitionMap: partitionMap_,
    mapWithIndex: mapWithIndex_,
    reduceWithIndex: reduceWithIndex_,
    foldMapWithIndex: foldMapWithIndex_,
    reduceRightWithIndex: reduceRightWithIndex_,
    filterMapWithIndex: filterMapWithIndex_,
    filterWithIndex: filterWithIndex_,
    partitionMapWithIndex: partitionMapWithIndex_,
    partitionWithIndex: partitionWithIndex_,
    traverseWithIndex: traverseWithIndex_,
    wither: wither_,
    wilt: wilt_
};
