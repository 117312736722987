/**
 * Applies a bitwise XOR to the contents of two buffers. Returns a new buffer.
 *
 * @example
 * bitwise.buffer.xor(a, b, false) => Buffer(a XOR b)
 *
 * @param {Buffer} a first buffer
 * @param {Buffer} b second buffer
 * @param {Boolean} isLooping loop through first buffer
 * @return {Buffer} a XOR b
 */
export default (function (a, b, isLooping) {
    if (isLooping === void 0) { isLooping = false; }
    var length = isLooping ? b.length : a.length;
    var result = Buffer.alloc(length);
    for (var i = 0; i < length; i++) {
        var j = isLooping ? i % a.length : i;
        result[i] = a[j] ^ b[i];
    }
    return result;
});
