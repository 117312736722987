import { ERC721TokenType } from "@imtbl/imx-sdk";
import { endLoadingButton, showFileCard, startLoadingButton } from "../main";
import { wallet } from "./classes/Wallet";
import { printFileInfo } from "./constants";
import { formatBytes } from "./utils/file";
import { batchNFTTransfer } from "./utils/imxAPI";
import { log } from "./utils/logger";

const HEADERS = [
  "Model_ID",
  "Quantity",
  "Collection_Address",
  "Receiver_Address",
];

function arraysEqual(a1, a2) {
  return JSON.stringify(a1) == JSON.stringify(a2);
}

export const uploadFile = (file) => {
  const reader = new FileReader();

  var hasError = false;
  var successLog = "";

  var assetsToSend = [];

  reader.addEventListener("progress", (event) => {
    const progress = Math.round((event.loaded / event.total) * 100);
    const progressNum = document.getElementById("loadProgress");
    const progressLength = document.getElementById("progressValue");
    progressLength.style.width = `${progress}%`;
    progressNum.innerText = `${progress}%`;
  });

  reader.onloadstart = function (event) {
    log("Processing .......", true);
    showFileCard();

    startLoadingButton();
    printFileInfo(file.name, file.size);
  };

  reader.onload = () => {
    if (file.size / 1024 / 1024 > 1) {
      hasError = true;
      log("ERROR: File size is larger than 1 MB");
      return;
    }
    const str = reader.result;

    const headers = str
      .slice(0, str.indexOf("\n"))
      .replace(/[\r\n]/gm, "")
      .split(",");

    if (!arraysEqual(HEADERS, headers)) {
      log(
        `ERROR: Column format and copywriting is not correct, please format them according to the sample CSV.`
      );
      hasError = true;
      return;
    }

    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const filteredData = rows.filter(
      (item) => item.replace(" ", "").length > 0
    );

    const processedData = filteredData.map((row) => {
      const values = row.split(",");
      const eachObject = headers.reduce((obj, header, i) => {
        if (header !== "") {
          header.replace(" ", "");
          obj[header] = values[i];
        }
        return obj;
      }, {});

      return eachObject;
    });

    let tempAssets = JSON.parse(JSON.stringify({ ...wallet.getAssets() }));

    processedData.forEach((row, index) => {
      if (tempAssets[row?.Model_ID] === undefined) {
        log(
          `ERROR: Model ID ${row?.Model_ID} on line ${
            index + 2
          } of the document does not exist in the connected wallet...`
        );
        hasError = true;
      } else if (
        row.Receiver_Address === undefined ||
        !row?.Receiver_Address?.startsWith("0x")
      ) {
        log(`ERROR: Invalid receiver Address in row ${index + 2}...`);
        hasError = true;
      } else {
        for (let i = 1; i <= row.Quantity; i++) {
          let popped = tempAssets[row.Model_ID]?.pop();
          if (popped === undefined) {
            log(
              `ERROR: Connected wallet does not have enough supply for Model ID ${row.Model_ID}`
            );
            hasError = true;
          } else if (!hasError) {
            const { token_id, token_address } = popped;
            const toAddress = row.Receiver_Address.replace(/[\r\n]/gm, "");
            successLog = successLog.concat(
              `Token ID ${token_id} to Address ${toAddress} \n\n\n`
            );
            assetsToSend.push({
              type: ERC721TokenType.ERC721,
              tokenId: token_id,
              tokenAddress: token_address,
              toAddress: toAddress,
            });
          }
        }
      }
    });
    console.log(assetsToSend);
    if (!hasError) {
      log(successLog);
    }
  };

  reader.readAsText(file);

  reader.onloadend = function (event) {
    const transferButton = document.getElementById("transferButton");
    const fileSizeContainer = document.getElementById("fileSize");
    fileSizeContainer.innerHTML = formatBytes(file.size);
    endLoadingButton(hasError);
    transferButton.addEventListener("click", async function () {
      await batchNFTTransfer(assetsToSend);
    });
  };
};
