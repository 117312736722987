/**
 * Converts a bit array to a boolean array.
 *
 * @example toBoolean([0, 1]) => [false, true]
 * @param {Array} bits input data
 * @returns {Array} boolean bits
 */
export default (function (bits) {
    var result = [];
    for (var i = 0; i < bits.length; i++)
        result[i] = bits[i] === 1;
    return result;
});
